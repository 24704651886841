import React, { useEffect } from "react";
import Modal from "react-modal";
import detail_icon from "../../assets/icons/information-dark.svg";
import icon_correct_answer from "../../assets/icons/answer_correct.svg";
import icon_wrong_answer from "../../assets/icons/answer_wrong.svg";
import icon_missing_answer from "../../assets/icons/answer_null.svg";
import icon_not_counted from "../../assets/icons/not_counted.svg";
import icon_pt from "../../assets/icons/total_score.svg";
import icon_not_shure from "../../assets/icons/uncertain_icon.svg";

import { useTranslation, Trans } from 'react-i18next';
import { prefixedT } from "../../i18n/utils";

import "./Modal.css";

export default function DetailedResultsModal(props) {
  const onCloseModal = () => {
    props?.handleClose();
  };

  const { t, i18n } = useTranslation();

  const style = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: 10010,
    },
    content: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      maxWidth: "450px",
      border: "0",
      background: "#fff",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      borderRadius: "24px",
      outline: "none",
      padding: "0",
    },
  };

  useEffect(() => {}, [props]);

  return (
    <>
      <Modal
        closeTimeoutMS={300}
        isOpen={props.show}
        contentLabel="modal"
        onRequestClose={onCloseModal}
        className={props?.isBocconiDesign ? "bocconi" : "center_modal"}
        overlayClassName="bottom_modal_overlay"
        ariaHideApp={false}
        style={style}
      >
        <div className="modal-content">
          <div className="d-flex justify-content-center">
            <img
              alt="Errore"
              className="noselect header_image"
              src={detail_icon}
            />
          </div>
          <h2
            id="modalTitle"
            className="modal__title"
            style={{
              textAlign: "center",
              marginBottom: "16px",
              marginTop: "16px",
            }}
          >
            {t(prefixedT("RESULT_SCREEN.DETAILS"))}
          </h2>
          <div className="results">
            <div className="d-flex justify-content-between mt-2">
              <div className="d-flex">
                <img src={icon_correct_answer} className="me-1 icon_result" />
                <span className="text-2">{t(prefixedT("RESULT_SCREEN.CORRECT"))}</span>
                <strong className="ms-1 text-3">{props.data.correct}</strong>
              </div>
              <div className="d-flex">
                <strong className="me-1 text-3">
                  {Math.round(
                    (props.data.correct_score + Number.EPSILON) * 100
                  ) / 100}
                </strong>
                <img src={icon_pt} className="me-1" />
                <span className="text-2">{"p."}</span>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2">
              <div className="d-flex">
                <img src={icon_wrong_answer} className="me-1 icon_result" />
                <span className="text-2">{t(prefixedT("RESULT_SCREEN.WRONG"))}</span>
                <strong className="ms-1 text-3">{props.data.wrong}</strong>
              </div>
              <div className="d-flex">
                <strong className="me-1 text-3">
                  {Math.round((props.data.wrong_score + Number.EPSILON) * 100) /
                    100}
                </strong>
                <img src={icon_pt} className="me-1" />
                <span className="text-2">{"p."}</span>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2 mb-3">
              <div className="d-flex">
                <img src={icon_missing_answer} className="me-1 icon_result" />
                <span className="text-2">{t(prefixedT("RESULT_SCREEN.MISSING"))}</span>
                <strong className="ms-1 text-3">{props.data.missing}</strong>
              </div>
              <div className="d-flex">
                <strong className="me-1 text-3">
                  {Math.round(
                    (props.data.missing_score + Number.EPSILON) * 100
                  ) / 100}
                </strong>
                <img src={icon_pt} className="me-1" />
                <span className="text-2">p.</span>
              </div>
            </div>
            {props.data.not_counted ? (
              <div className="not_counted_box">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <img src={icon_not_counted} className="me-1 icon_result" />
                    <span className="text-2">{t(prefixedT("RESULT_SCREEN.NOT_COUNTED"))}</span>
                    <strong className="ms-1 text-3">
                      {props.data.not_counted}
                    </strong>
                  </div>
                  <div className="d-flex">
                    <strong className="me-1 text-3">{"0"}</strong>
                    <img src={icon_pt} className="me-1" />
                    <span className="text-2">{"p."}</span>
                  </div>
                </div>
                <p className="mb-0">
                  {
                    t(prefixedT("RESULT_SCREEN.NOT_COUNTED_REASON"))
                  }
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          <button
            id="closeModalButton"
            onClick={onCloseModal}
            className="mx-auto w-100 mt-4"
          >
            {t(prefixedT("BUTTONS.CLOSE"))}
          </button>
        </div>
      </Modal>
    </>
  );
}
