import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SimulationContext } from "../../context/SimulationContext";
import Header from "../../_components/TopBar/TopBar";
import ErrorModal from "../../_components/Modals/ErrorModal";

import icon_result from "../../assets/icons/high-five-hand.svg";
import icon_time from "../../assets/icons/result_time.svg";
import icon_avg_time from "../../assets/icons/avg_time.svg";
import icon_punteggio from "../../assets/icons/total_score.svg";
import icon_correct_answer from "../../assets/icons/answer_correct.svg";
import icon_wrong_answer from "../../assets/icons/answer_wrong.svg";
import icon_missing_answer from "../../assets/icons/answer_null.svg";
import icon_not_counted from "../../assets/icons/not_counted.svg";
import icon_bulb from "../../assets/icons/bulb.svg";
import icon_books from "../../assets/icons/books.svg";
import icon_cup from "../../assets/icons/cup.svg";
import icon_degree from "../../assets/icons/degree.svg";
import logo_tf from "../../assets/icons/logo_tf_final.svg";
import icon_pass from "../../assets/icons/pass_test.svg";
import icon_not_pass from "../../assets/icons/not_pass_test.svg";
import icon_correct_not_shure from "../../assets/icons/correct_uncertain.svg";
import icon_wrong_not_shure from "../../assets/icons/uncertain_wrong.svg";
import close_icon from "../../assets/icons/close.svg";
import warning_icon from "../../assets/icons/warning-alert.svg";
import icon_grafico from "../../assets/icons/icon_grafico.svg";

import "./ResultScreen.css";
import { ResultGraph } from "./ResultGraph";
import DetailedResultsModal from "../../_components/Modals/DetailedResultsModal";
import DetailedPassModal from "../../_components/Modals/DetailedPassModal";
import CloseResultModal from "../../_components/Modals/CloseResultModal";
import DisciplineProgressBox from "./DisciplineProgressBox";
import OFAModal from "../../_components/Modals/OFAModal";
import ReducedResultGraph from "./ReducedResultGraph";

import { useTranslation, Trans } from 'react-i18next';
import { prefixedT } from "../../i18n/utils";

export default function ResultScreen() {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { simulationData } = useContext(SimulationContext);

  const [errorModalDetails, setErrorModalDetails] = useState({
    show: false,
    text: "",
    title: "",
  });
  const [step, setStep] = useState(0);
  const [showDetailedResults, setShowDetailedResults] = useState(false);
  const [showPassModal, setShowPassModal] = useState(false);
  const [showCloseResultModal, setShowCloseResultModal] = useState(false);
  const [showOFAModal, setShowOFAModal] = useState(false);

  const handleCloseErrorModal = () => {
    setErrorModalDetails({
      ...errorModalDetails,
      show: false,
    });
  };

  const handleOpenCloseResultModal = () => {
    setShowCloseResultModal(true);
  };

  const openDetailedResults = () => {
    setShowDetailedResults(true);
  };

  const checkHasOFA = () => {
    return simulationData.stats.disciplines_score.find(
      (d) => d.is_ofa || d.is_reset
    );
  };

  const checkDisciplineResetAlert = (d) => {
    return (
      (d.reset_score && d.total_score < d.reset_score) ||
      (d.reset_correct_count && d.correct < d.reset_correct_count)
    );
  };

  const checkDisciplineOfaAlert = (d) => {
    return (
      !checkDisciplineResetAlert(d) &&
      ((d?.ofa_score && d?.total_score < d?.ofa_score) ||
        (d?.ofa_correct_count && d?.correct < d?.ofa_correct_count))
    );
  };

  const checkOfaAlert = () => {
    return simulationData.stats.disciplines_score.find(
      (d) => (d.is_ofa || d.is_reset) && !checkResetAlert(d) && checkOfaAlert(d)
    );
  };

  const checkResetAlert = () => {
    return simulationData.stats.disciplines_score.find(
      (d) => (d.is_ofa || d.is_reset) && checkDisciplineResetAlert(d)
    );
  };

  useEffect(() => {
    if (!simulationData) navigate("/", { replace: true });
  }, []);

  return (
    <div className="noselect simulation_screen">
      <Header
        university_logo={simulationData?.admission_test}
        isBocconiDesign={simulationData?.is_bocconi_design}
      />
      {simulationData && simulationData.stats && (
        <div className="row mt-4 justify-content-center">
          <div className="col p-3">
            {step === 0 && (
              <>
                {simulationData.type === "incorrect_questions" && (
                  <div className="d-flex justify-content-end">
                    <img
                      src={close_icon}
                      onClick={handleOpenCloseResultModal}
                      className="pointer"
                    />
                  </div>
                )}
                <img src={icon_result} className="mx-auto section-icon" />
                {simulationData.type === "incorrect_questions" ? (
                  <h1 className="text-center mt-2">{"Ripasso completato"}</h1>
                ) : (
                  <h1 className="text-center mt-2">
                    {simulationData.is_bocconi_design
                      ? t(prefixedT('RESULT_SCREEN.PRACTICE_COMPLETED'))
                      : t(prefixedT('RESULT_SCREEN.SIMULATION_COMPLETED'))}
                  </h1>
                )}
                <div className="result__cards">
                  <div className="card">
                    {simulationData.type === "incorrect_questions" ? (
                      <div className="result_label_without_margin mt-5">
                        <span>{t(prefixedT('RESULT_SCREEN.YOU_CORRECTED'))}</span>
                      </div>
                    ) : (
                      <div className="result_label">
                        <img src={icon_time} />
                        <span>{t(prefixedT('RESULT_SCREEN.TOTAL_TIME'))}</span>
                      </div>
                    )}
                    {simulationData.type === "incorrect_questions" ? (
                      <>
                        <div className="d-flex justify-content-center align-items-center mt-2">
                          <span
                            style={{ fontSize: "32px" }}
                            className="big_result me-1"
                          >
                            {simulationData?.stats?.correct -
                              simulationData?.stats?.uncertain_correct}
                          </span>
                          <img
                            style={{ minWidth: "24px" }}
                            src={icon_correct_answer}
                          />
                        </div>
                        <p style={{ fontSize: "14px", color: "#373737BF" }}>
                          {`${t(prefixedT("RESULT_SCREEN.QUESTIONS_OF"))} ${simulationData?.questions?.length}`}
                        </p>
                      </>
                    ) : (
                      <>
                        <span className="big_result mt-2">
                          {new Date(
                            simulationData.consumed_time * 1000
                          ).getMinutes()}
                          m{" "}
                          {new Date(
                            simulationData.consumed_time * 1000
                          ).getSeconds()}
                          s
                        </span>
                        <div className="result_label_small mt-4">
                          <img src={icon_avg_time} />
                          <span>{t(prefixedT("RESULT_SCREEN.AVG_TIME_FOR_QUESTION"))}</span>
                        </div>
                        <span className="small_result mt-2">
                          {simulationData.consumed_time /
                            simulationData.questions.length >
                          60
                            ? Math.round(
                                simulationData.consumed_time /
                                  simulationData.questions.length /
                                  60
                              ) +
                              "m " +
                              Math.round(
                                (simulationData.consumed_time /
                                  simulationData.questions.length) %
                                  60
                              )
                            : Math.round(
                                (simulationData.consumed_time /
                                  simulationData.questions.length) *
                                  10
                              ) / 10}
                          {"s"}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="card">
                    {simulationData.type !== "incorrect_questions" && (
                      <div className="result_label">
                        <img src={icon_punteggio} />
                        <span>{t(prefixedT("RESULT_SCREEN.SCORE"))}</span>
                      </div>
                    )}
                    {simulationData.type !== "incorrect_questions" && (
                      <span className="big_result mt-2">
                        {Math.round(
                          (simulationData?.stats?.total_score +
                            Number.EPSILON) *
                            100
                        ) / 100}{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            lineHeight: "24px",
                            color: "#373737BF",
                          }}
                        >
                          {`/ ${simulationData?.stats?.max_score}`}
                        </span>
                      </span>
                    )}
                    {simulationData.type !== "incorrect_questions" ? (
                      <div className="d-flex justify-content-between">
                        <div className="summary_result">
                          <img className="mx-auto" src={icon_correct_answer} />
                          <span>{t(prefixedT("RESULT_SCREEN.CORRECT"))}</span>
                          <span>{simulationData?.stats?.correct}</span>
                        </div>
                        <div className="summary_result ms-4 me-4">
                          <img className="mx-auto" src={icon_wrong_answer} />
                          <span>{t(prefixedT("RESULT_SCREEN.WRONG"))}</span>
                          <span>{simulationData?.stats?.wrong}</span>
                        </div>
                        <div className="summary_result">
                          <img className="mx-auto" src={icon_missing_answer} />
                          <span>{t(prefixedT("RESULT_SCREEN.MISSING"))}</span>
                          <span>{simulationData?.stats?.missing}</span>
                        </div>
                        {simulationData.stats.not_counted ? (
                          <div className="summary_result ms-4 ">
                            <img className="mx-auto" src={icon_not_counted} />
                            <span>{"N.C."}</span>
                            <span>{simulationData.stats.not_counted}</span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div className="">
                        <p style={{ fontSize: "14px" }}>
                          {}
                          {`${t(prefixedT("RESULT_SCREEN.REMAINING_ANSWER"))} ${
                            simulationData?.questions?.length -
                            simulationData?.stats?.correct
                          }:`}
                        </p>
                        <div className="d-flex justify-content-between mt-3">
                          <div className="d-flex">
                            <img
                              src={icon_correct_not_shure}
                              style={{ width: "24px" }}
                              className="me-1 icon_result"
                            />
                            <span className="text-2">
                              {t(prefixedT("RESULT_SCREEN.CORRECT_UNSURE"))}
                            </span>
                          </div>
                          <div className="d-flex">
                            <strong className="ms-1 text-3">
                              {simulationData?.stats?.uncertain_correct}
                            </strong>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                          <div className="d-flex">
                            <img
                              src={icon_wrong_not_shure}
                              style={{ width: "24px" }}
                              className="me-1 icon_result"
                            />
                            <span className="text-2">
                              {t(prefixedT("RESULT_SCREEN.WRONG_UNSURE"))}
                            </span>
                          </div>
                          <div className="d-flex">
                            <strong className="ms-1 text-3">
                              {simulationData?.stats?.uncertain_wrong}
                            </strong>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                          <div className="d-flex">
                            <img
                              src={icon_wrong_answer}
                              className="me-1 icon_result"
                            />
                            <span className="text-2">{t(prefixedT("RESULT_SCREEN.WRONG"))}</span>
                          </div>
                          <div className="d-flex">
                            <strong className="ms-1 text-3">
                              {simulationData?.stats?.wrong -
                                simulationData?.stats?.uncertain_wrong}
                            </strong>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                          <div className="d-flex">
                            <img
                              src={icon_not_counted}
                              className="me-1 icon_result"
                            />
                            <span className="text-2">{t(prefixedT("RESULT_SCREEN.MISSING"))}</span>
                          </div>
                          <div className="d-flex">
                            <strong className="ms-1 text-3">
                              {simulationData?.stats?.missing}
                            </strong>
                          </div>
                        </div>
                      </div>
                    )}
                    {simulationData.type !== "incorrect_questions" && (
                      <span
                        onClick={openDetailedResults}
                        className="modal_anchor pointer mt-3"
                      >
                        {t(prefixedT("RESULT_SCREEN.DETAILS"))}
                      </span>
                    )}
                  </div>
                  {simulationData.is_official_structure &&
                    simulationData.admission_test
                      .previous_blocking_scores[0] && (
                      <div
                        className={
                          simulationData.admission_test
                            .previous_blocking_scores[0].score >
                          simulationData.stats.total_score
                            ? "card test_not_pass"
                            : "card test_pass"
                        }
                      >
                        <div className="result_label">
                          <img src={icon_degree} />
                          <span>
                            {t(prefixedT("RESULT_SCREEN.LAST_YEAR_COMPARISON"))}
                          </span>
                        </div>

                        {simulationData.admission_test
                          .previous_blocking_scores[0].score >
                        simulationData.stats.total_score ? (
                          <div className="summary_result">
                            <img className="mx-auto" src={icon_not_pass} />
                            <span className="label_small">
                              {t(prefixedT("RESULT_SCREEN.IN_THIS_CASE"))}
                            </span>
                            <span className="big_result_text">
                              {
                                t(prefixedT("RESULT_SCREEN.NOT_IN_RANGE"))
                              }
                            </span>
                          </div>
                        ) : (
                          <div className="summary_result">
                            <img className="mx-auto" src={icon_pass} />
                            <span className="label_small">
                              {t(prefixedT("RESULT_SCREEN.IN_THIS_CASE"))}
                            </span>
                            <span className="big_result_text">
                              {
                                t(prefixedT("RESULT_SCREEN.IN_RANGE"))
                              }
                            </span>
                          </div>
                        )}

                        <span
                          onClick={() => {
                            setShowPassModal(true);
                          }}
                          className="modal_anchor pointer mt-5"
                        >
                          {t(prefixedT("RESULT_SCREEN.DETAILS"))}
                        </span>
                        <DetailedPassModal
                          show={showPassModal}
                          user_score={simulationData.stats.total_score}
                          previous_score={
                            simulationData.admission_test
                              .previous_blocking_scores[0]
                          }
                          handleClose={() => {
                            setShowPassModal(false);
                          }}
                          isBocconiDesign={simulationData.is_bocconi_design}
                        />
                      </div>
                    )}
                </div>
                <div className="mt-5 mx-auto" style={{ maxWidth: "330px" }}>
                  {(simulationData?.type !== "comparative" || simulationData?.show_review )&& (
                    <button
                      id="closeModalButton"
                      className="w-100"
                      onClick={() => {
                        navigate("/revision", { replace: true });
                      }}
                    >
                      <img src={icon_bulb} className="me-2" />
                      {t(prefixedT("RESULT_SCREEN.REVIEW_QUESTIONS"))}
                    </button>
                  )}
                  {simulationData.type !== "incorrect_questions" && (
                    <div className="d-flex navigation_buttons">
                      <button
                        id="closeModalButton"
                        disabled={true}
                        className="mt-2 white w-100"
                      >
                        {t(prefixedT("BUTTONS.BACK"))}
                      </button>
                      <button
                        id="closeModalButton"
                        onClick={() => {
                          setStep(1);
                        }}
                        className="mt-2 ms-2 white w-100"
                      >
                        {t(prefixedT("BUTTONS.AHEAD"))}
                      </button>
                    </div>
                  )}
                </div>
                <DetailedResultsModal
                  data={simulationData.stats}
                  show={showDetailedResults}
                  handleClose={() => {
                    setShowDetailedResults(false);
                  }}
                  isBocconiDesign={simulationData?.is_bocconi_design}
                />
              </>
            )}
            {step === 1 && (
              <>
                {(simulationData.tag !== "standard" ||
                  simulationData.type !== "comparative") && (
                  <div className="d-flex justify-content-end">
                    <img
                      src={close_icon}
                      onClick={handleOpenCloseResultModal}
                      className="pointer"
                    />
                  </div>
                )}
                <img src={icon_grafico} className="mx-auto section-icon" />
                <h1 className="text-center mt-2">{t(prefixedT("RESULT_SCREEN.PERFORMANCE_GRAPH"))}</h1>
                <div
                  className="mx-auto"
                  style={{ maxWidth: "400px", minHeight: simulationData?.stats?.disciplines_score?.length > 2 ? "400px" : "300px" }}
                >
                  {simulationData?.stats?.disciplines_score?.length > 2 ? (
                    <ResultGraph
                      data={simulationData.stats.disciplines_score}
                    />
                  ) : (
                    <ReducedResultGraph
                      data={simulationData.stats.disciplines_score}
                    />
                  )}
                </div>
                <div className="mt-5 mx-auto" style={{ maxWidth: "330px" }}>
                  {(simulationData?.type !== "comparative" || simulationData?.show_review )&& (
                    <button
                      id="closeModalButton"
                      className="w-100"
                      onClick={() => {
                        navigate("/revision", { replace: true });
                      }}
                    >
                      <img src={icon_bulb} className="me-2" />
                      {t(prefixedT("RESULT_SCREEN.REVIEW_QUESTIONS"))}
                    </button>
                  )}
                  <div className="d-flex navigation_buttons">
                    <button
                      id="closeModalButton"
                      onClick={() => {
                        setStep(0);
                      }}
                      className="mt-2 white w-100"
                    >
                      {t(prefixedT("BUTTONS.BACK"))}
                    </button>
                    <button
                      id="closeModalButton"
                      disabled={
                        !checkHasOFA() && simulationData.type !== "comparative"
                      }
                      onClick={() => {
                        setStep(checkHasOFA() ? 2 : 3);
                      }}
                      className="mt-2 ms-2 white w-100"
                    >
                      {t(prefixedT("BUTTONS.AHEAD"))}
                    </button>
                  </div>
                </div>
              </>
            )}
            {step === 2 && (
              <>
                <div className="d-flex justify-content-end">
                  <img
                    src={close_icon}
                    onClick={handleOpenCloseResultModal}
                    className="pointer"
                  />
                </div>
                <img src={icon_books} className="mx-auto section-icon" />
                <h1 className="text-center mt-2">
                  {t(prefixedT("RESULT_SCREEN.OFA_TITLE"))}
                </h1>
                <span
                  onClick={() => {
                    setShowOFAModal(true);
                  }}
                  className="text-center d-block modal_anchor pointer mt-2"
                >
                  {"Cosa sono gli OFA?"}
                </span>
                {checkHasOFA() && (checkOfaAlert() || checkResetAlert()) ? (
                  <div className="d-flex justify-content-center mt-4">
                    {checkResetAlert() && (
                      <div style={{ maxWidth: "390px" }}>
                        <p className="text-center">
                          {"Non hai raggiunto il punteggio minimo in:"}
                        </p>
                        {simulationData.stats.disciplines_score.map((d) => {
                          if (
                            (d.is_ofa || d.is_reset) &&
                            checkDisciplineResetAlert(d)
                          )
                            return <DisciplineProgressBox discipline={d} />;
                          else return "";
                        })}
                        <div
                          className="mt-5 p-3"
                          style={{
                            border: "4px solid #BC2A2A",
                            borderRadius: "16px",
                          }}
                        >
                          <img
                            src={warning_icon}
                            style={{ maxWidth: "24px" }}
                            className="d-block mx-auto"
                          />
                          <p className="mt-2 mb-0">
                            {
                              "Ricorda: i moduli in cui non hai raggiunto il punteggio minimo non forniscono punti!"
                            }
                          </p>
                        </div>
                      </div>
                    )}
                    {checkOfaAlert() && (
                      <div style={{ maxWidth: "390px" }}>
                        <p>
                          {
                            "Bene, ma puoi migliorare! Al test ufficiale, avresti ricevuto OFA in:"
                          }
                        </p>
                        {simulationData.stats.disciplines_score.map((d) => {
                          if (
                            (d.is_ofa || d.is_reset) &&
                            checkDisciplineOfaAlert(d)
                          )
                            return <div></div>;
                          else return "";
                        })}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="mx-auto" style={{ maxWidth: "350px" }}>
                    <img className="d-block mx-auto mt-10" src={icon_result} />
                    <p className="text-center mt-2">
                      {"Grande! Con questo punteggio, non avresti ricevuto "}
                      <img src={icon_books} />
                      <strong className="text-tf">{" OFA "}</strong>
                      {"al test ufficiale."}
                    </p>
                  </div>
                )}
                <div className="mt-5 mx-auto" style={{ maxWidth: "330px" }}>
                  {(simulationData?.type !== "comparative" || simulationData?.show_review )&& (
                    <button
                      id="closeModalButton"
                      className="w-100"
                      onClick={() => {
                        navigate("/revision", { replace: true });
                      }}
                    >
                      <img src={icon_bulb} className="me-2" />
                      {t(prefixedT("RESULT_SCREEN.REVIEW_QUESTIONS"))}
                    </button>
                  )}
                  <div className="d-flex navigation_buttons">
                    <button
                      id="closeModalButton"
                      onClick={() => {
                        setStep(1);
                      }}
                      className="mt-2 white w-100"
                    >
                      {t(prefixedT("BUTTONS.BACK"))}
                    </button>
                    <button
                      id="closeModalButton"
                      disabled={simulationData.type !== "comparative"}
                      onClick={() => {
                        setStep(3);
                      }}
                      className="mt-2 ms-2 white w-100"
                    >
                      {t(prefixedT("BUTTONS.AHEAD"))}
                    </button>
                  </div>
                </div>
                <OFAModal
                  show={showOFAModal}
                  handleClose={() => {
                    setShowOFAModal(false);
                  }}
                />
              </>
            )}
            {step === 3 && (
              <>
                <div className="d-flex justify-content-end">
                  <img
                    src={close_icon}
                    onClick={handleOpenCloseResultModal}
                    className="pointer"
                  />
                </div>
                <img src={icon_cup} className="mx-auto section-icon" />
                <h1 className="text-center mt-2">{t(prefixedT("RESULT_SCREEN.SCOREBOARD"))}</h1>
                <div className="mx-auto" style={{ maxWidth: "350px" }}>
                  <img className="d-block mx-auto mt-10" src={logo_tf} />
                  <p className="text-center mt-2">
                    {
                      t(prefixedT("RESULT_SCREEN.SCOREBOARD_TEXT"))
                    }
                  </p>
                </div>
                <div className="mt-10 mx-auto" style={{ maxWidth: "330px" }}>
                  <button
                    id="closeModalButton"
                    onClick={() => {
                      setStep(1);
                    }}
                    className="mt-2 white w-100"
                  >
                    {t(prefixedT("BUTTONS.BACK"))}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <ErrorModal
        show={errorModalDetails.show}
        title={errorModalDetails.title}
        text={errorModalDetails.text}
        handleClose={handleCloseErrorModal}
      />
      <CloseResultModal
        show={showCloseResultModal}
        handleClose={() => {
          setShowCloseResultModal(false);
        }}
        isBocconiDesign={simulationData?.is_bocconi_design}
      />
    </div>
  );
}
