import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

import icon_books from "../../assets/icons/books.svg"
import icon_books_grey from "../../assets/icons/books_grey.svg"

export default function DisciplineProgressBox (props){
    const { discipline } = props
    return (
        <div className="mt-2">
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center mb-2">
                    <img style={{maxWidth:'16px', maxHeight:'16px'}} src={discipline.discipline_image_url} />
                    <span className="ms-1 discipline_name_text">{discipline.discipline_name}</span>
                </div>
                <span className="discipline_name_text">{discipline.total_score}/{discipline.max_score}</span>
            </div>
            <ProgressBar
                percent={discipline.total_score/discipline.max_score}
                filledBackground="#296D9F"
                stepPositions={(discipline.reset_score || discipline.ofa_score) ? [
                    (discipline.reset_score*100)/discipline.max_score,
                    (discipline.ofa_score*100)/discipline.max_score
                ] : [
                    (discipline.reset_correct_count*100)/discipline.max_score,
                    (discipline.ofa_correct_count*100)/discipline.max_score
                ]}
            >
                <Step position={(discipline.reset_score*100)/discipline.max_score}>
                {({ accomplished }) => (
                    <div className="d-block" style={{transform:'translate(0px, 14px)'}}>
                        <div style={{ display:'block', height:'10px', width:'10px', background:`${accomplished ? '#fff' : '#9C9C9C'}`, borderRadius:'50%' }} width='10' className='mx-auto'/>
                        <img
                        style={{ display:'block'}}
                        width="20"
                        src={icon_books}
                        className='mt-2'
                        />
                    </div>
                )}
                </Step>
                <Step  position={(discipline.ofa_score*100)/discipline.max_score}>
                {({ accomplished }) => (
                    <div className="d-block" style={{transform:'translate(0px, 14px)'}}>
                        <div style={{ display:'block', height:'10px', width:'10px', background:`${accomplished ? '#fff' : '#9C9C9C'}`, borderRadius:'50%' }} width='10' className='mx-auto'/>
                        <img
                        style={{ display:'block'}}
                        width="20"
                        src={icon_books_grey}
                        className='mt-2'
                        />
                    </div>
                )}
                </Step>
            </ProgressBar>
        </div>
    )
}