import { useState } from "react";
import './ImageZoom.css' 

export default function ImageZoom (props) {
    const [isOpen, setIsOpen] = useState(false)

    return(
        <div onClick={()=>{setIsOpen(!isOpen)}} className={"zoomable " + (isOpen ? "zoom-in" : "zoom-out")}>
            {props.children}
            <span className="zoom_icon"></span>
        </div>
    )
}