import React, { useState, useEffect, useRef, useContext } from "react";
import { useTimer } from "react-timer-hook";
import {
  highlightSelection,
  eraseHighlightedSelection,
  insertMyText,
} from "./readerUtils";
import { QuestionPapersContext } from "../../context/SimulationContext";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import "./PaperReader.css";
import back_img from "../../assets/icons/back.svg";
import back_img_bocconi from "../../assets/icons/bocconi/arrow-back.svg";
import bookmark_img from "../../assets/icons/reader/bookmark.svg";
import highlighter_img from "../../assets/icons/reader/highlighter.svg";
import eraser_img from "../../assets/icons/reader/eraser.svg";
import font_img from "../../assets/icons/reader/character.svg";
import bookmark_active_img from "../../assets/icons/reader/bookmark_active.svg";
import highlighter_active_img from "../../assets/icons/reader/highlighter_active.svg";
import eraser_active_img from "../../assets/icons/reader/eraser_active.svg";
import font_active_img from "../../assets/icons/reader/character_active.svg";

import bookmark_img_bocconi from "../../assets/icons/bocconi/reader/bookmark.svg";
import highlighter_img_bocconi from "../../assets/icons/bocconi/reader/highlighter.svg";
import eraser_img_bocconi from "../../assets/icons/bocconi/reader/eraser.svg";
import font_img_bocconi from "../../assets/icons/bocconi/reader/character.svg";
import bookmark_active_img_bocconi from "../../assets/icons/bocconi/reader/bookmark_active.svg";
import highlighter_active_img_bocconi from "../../assets/icons/bocconi/reader/highlighter_active.svg";
import eraser_active_img_bocconi from "../../assets/icons/bocconi/reader/eraser_active.svg";
import font_active_img_bocconi from "../../assets/icons/bocconi/reader/character_active.svg";

import { useTranslation, Trans } from 'react-i18next';
import { prefixedT } from "../../i18n/utils";

import Lottie from "lottie-react";
import timerAnimation from "../../assets/icons/timer.json";
import AvatarReaderOverlay from "./AvatarReaderOverlay";

export default function PaperReader(props) {
  const { questionPapersData, setQuestionPapersData } = useContext(
    QuestionPapersContext
  );
  const [selectedTool, setSelectedTool] = useState(null);
  let paperRef = useRef();

  const { t, i18n } = useTranslation();

  const GetFromLocalStorage = function (key) {
    return JSON.parse(localStorage.getItem(key));
  };

  const { seconds, minutes, hours, restart } = useTimer({
    expiryTimestamp: props.expiryTime,
    onExpire: () => {},
  });

  const seconds_ref = useRef(seconds);
  const minutes_ref = useRef(minutes);
  const hours_ref = useRef(hours);

  const formatText = (original_text = "") => {
    return original_text
      ?.replaceAll("\n", "<br /><br />")
      ?.replaceAll("$$", "$");
  };

  const tools = [
    {
      type: "bookmark",
      image: props?.isBocconiDesign ? bookmark_img_bocconi : bookmark_img,
      active_image: props?.isBocconiDesign
        ? bookmark_active_img_bocconi
        : bookmark_active_img,
    },
    {
      type: "highlighter",
      image: props?.isBocconiDesign ? highlighter_img_bocconi : highlighter_img,
      active_image: props?.isBocconiDesign
        ? highlighter_active_img_bocconi
        : highlighter_active_img,
    },
    {
      type: "eraser",
      image: props?.isBocconiDesign ? eraser_img_bocconi : eraser_img,
      active_image: props?.isBocconiDesign
        ? eraser_active_img_bocconi
        : eraser_active_img,
    },
    {
      type: "font",
      image: props?.isBocconiDesign ? font_img_bocconi : font_img,
      active_image: props?.isBocconiDesign
        ? font_active_img_bocconi
        : font_active_img,
    },
  ];

  useEffect(() => {
    seconds_ref.current = seconds;
    minutes_ref.current = minutes;
    hours_ref.current = hours;
  }, [seconds]);

  useEffect(() => {
    if (!props.isEnded) restart(props.expiryTime);
  }, [props.expiryTime]);

  const handleUpdatePaperStatus = () => {
    let papers = questionPapersData.slice();
    for (let i in papers) {
      if (papers[i]?.question_number === props.paperData.question_number) {
        papers[i]["paper"] = paperRef.current.innerHTML;
        setQuestionPapersData(papers);
        break;
      }
    }
  };

  const toggleSelectedTool = (tool) => {
    if (tool === selectedTool) setSelectedTool(null);
    else setSelectedTool(tool);
  };

  const formatTime = (time) => {
    return String(time).padStart(2, "0");
  };

  const onMouseUp = (e) => {
    const s = window.getSelection().toString();
    switch (selectedTool) {
      case "bookmark":
        insertMyText(e);
        break;
      case "highlighter":
        highlightSelection();
        break;
      case "eraser":
        eraseHighlightedSelection();
        break;
      default:
        return;
    }
    handleUpdatePaperStatus();
  };

  const handleChangeFont = (e) => {
    let papers = questionPapersData.slice();
    for (let i in papers) {
      if (papers[i]?.question_number === props.paperData?.question_number) {
        papers[i]["fontSize"] = e;
        setQuestionPapersData(papers);
        break;
      }
    }
  };

  return (
    <div className="reader_page">
      <div className="container">
        <div className="reader_header">
          <span onClick={props?.handleCloseReader} className="backButtonReader">
            <img
              style={{ marginTop: -3 }}
              src={props?.isBocconiDesign ? back_img_bocconi : back_img}
              alt="Indietro"
            />
            {`${t(prefixedT("QUESTION.QUESTION"))} ${props.data.virtual_question_number}`}
          </span>
          <div
            className={`d-flex mt-3 ${
              props.isEnded ? "justify-content-end" : "justify-content-between"
            }`}
          >
            {!props.isEnded && (
              <div className="d-flex align-items-top justify-content-start">
                <Lottie
                  animationData={timerAnimation}
                  autoplay={true}
                  loop={100000}
                  style={{ width: "35px" }}
                  className="d-block me-2"
                />
                <div className={"exam_timer "}>
                  <span>{formatTime(hours)}</span>:
                  <span>{formatTime(minutes)}</span>:
                  <span>{formatTime(seconds)}</span>
                </div>
              </div>
            )}
            <div className="reader_controllerd">
              <div className="reader_controller d-flex">
                {tools.map((tool) => {
                  return (
                    <div
                      key={tool.type}
                      className={
                        "tool " + (selectedTool === tool.type ? "active" : "")
                      }
                      onClick={() => {
                        toggleSelectedTool(tool.type);
                      }}
                    >
                      {selectedTool !== tool.type ? (
                        <img src={tool.image} />
                      ) : (
                        <img src={tool.active_image} />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className={"enableSelect reader_content " + selectedTool} onMouseUp={onMouseUp}>
          <p
            style={{
              whiteSpace: "pre-wrap",
              fontSize: props?.paperData?.fontSize + "px",
              lineHeight: "120%",
              fontFamily: "Georgia",
              fontWeight: "normal",
              color: "#272727",
            }}
            dangerouslySetInnerHTML={{
              __html: formatText(props?.paperData?.paper),
            }}
            ref={paperRef}
          />
        </div>
        {selectedTool === "font" && (
          <div className="reader_font_selector">
            <div className="slider_container">
              <span className="me-2" style={{ fontSize: "12px" }}>
                {"A"}
              </span>
              <Slider
                min={12}
                max={32}
                step={4}
                dots={true}
                value={props?.paperData?.fontSize}
                onChange={handleChangeFont}
              />
              <span className="ms-2" style={{ fontSize: "32px" }}>
                {"A"}
              </span>
            </div>
          </div>
        )}
        {!GetFromLocalStorage("avatar_reader_show") && <AvatarReaderOverlay />}
      </div>
    </div>
  );
}
