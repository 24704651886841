import React, { useEffect } from "react";
import Modal from "react-modal"
import detail_icon from "../../assets/icons/degree.svg";
import icon_pass from "../../assets/icons/pass_test.svg"
import icon_not_pass from "../../assets/icons/not_pass_test.svg"
import info_icon from "../../assets/icons/info_icon.svg"


import { useTranslation, Trans } from 'react-i18next';
import { prefixedT } from "../../i18n/utils";

import './Modal.css'

export default function DetailedPassModal(props) {

    const { t, i18n } = useTranslation();
    const onCloseModal = () => {
        props?.handleClose();
    }
    const style = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 10010
        },
        content: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: "translate(-50%, -50%)",
            width: '100%',
            maxWidth: "450px",
            border: '0',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: "24px",
            outline: 'none',
            padding: '0'
        }
    }

    useEffect(() => {

    }, [props])

    return (
        <>
            <Modal
                closeTimeoutMS={300}
                isOpen={props.show}
                contentLabel="modal"
                onRequestClose={onCloseModal}
                className="center_modal"
                overlayClassName="bottom_modal_overlay"
                ariaHideApp={false}
                style={style}
            >
                <div className="modal-content">
                    <div className="d-flex justify-content-center">
                        <img
                            alt="Errore"
                            className="noselect header_image_big"
                            src={detail_icon}
                        />
                    </div>
                    <h2 id="modalTitle" className="modal__title" style={{ textAlign: 'center', marginBottom: "16px", marginTop: "16px" }}>Ultimo punteggio utile a.a. precedente</h2>
                    <div className="d-flex justify-content-around">
                        <div className="text-center">
                            <span className="text-center details-label">{t(prefixedT("RESULT_SCREEN.MODALS.DETAILED_SCORE_TITLE"))}</span>
                            <div className="d-flex align-items-center justify-content-center details-score">
                                <span className="me-1">{props.user_score}</span>
                                {(props.previous_score.score > props.user_score) ?
                                    <img src={icon_not_pass} />
                                :
                                    <img src={icon_pass}/>
                                }
                            </div>
                        </div>
                        <div className="text-center">
                            <span className="text-center details-label">{t(prefixedT("RESULT_SCREEN.MODALS.LAST_YEAR_VALID_SCORE"))}</span>
                            <span className="d-flex align-items-center justify-content-center details-score">
                                {props.previous_score.score}
                            </span>
                        </div>
                    </div>
                    
                    <p className="mt-4 text-details">{t(prefixedT("RESULT_SCREEN.MODALS.YOU_ARE"))}<span style={(props.previous_score.score > props.user_score) ? {color:"#C63838", fontWeight:"800"} : {color:"#65AA20", fontWeight:"800"}}>{props.user_score - props.previous_score.score}</span> {t(prefixedT("RESULT_SCREEN.MODALS.SCORE_DIFFERENCE_TEXT"))}</p>
                    <div className="d-flex justify-content-center mt-4"><img src={info_icon}/></div>
                    <p className="mt-1 text-details-2">
                        <Trans
                            i18nKey={prefixedT("RESULT_SCREEN.MODALS.DIFFERENCE_CALCULATION")}
                            components={{
                                strong: <strong />,
                                br: <br />,
                            }}
                            />
                    </p>
                    <button id="closeModalButton" onClick={onCloseModal} className="mx-auto w-100 mt-4">
                        {t(prefixedT("BUTTONS.UNDERSTOOD"))}
                    </button>

                </div>
            </Modal>
        </>
    );
}