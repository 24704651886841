import React, { useEffect } from "react";
import Modal from "react-modal"
import book_icon from "../../assets/icons/books.svg";
import book_icon_grey from "../../assets/icons/books_grey.svg";

import './Modal.css'

export default function OFAModal(props) {
    const onCloseModal = () => {
        props?.handleClose();
    }
    const style = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 10010
        },
        content: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: "translate(-50%, -50%)",
            width: '100%',
            maxWidth: "450px",
            border: '0',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: "24px",
            outline: 'none',
            padding: '0'
        }
    }

    useEffect(() => {

    }, [props])

    return (
        <>
            <Modal
                closeTimeoutMS={300}
                isOpen={props.show}
                contentLabel="modal"
                onRequestClose={onCloseModal}
                className="center_modal"
                overlayClassName="bottom_modal_overlay"
                ariaHideApp={false}
                style={style}
            >
                <div className="modal-content">
                    <div className="d-flex justify-content-center">
                        <img
                            alt="Errore"
                            className="noselect header_image_big"
                            src={book_icon}
                        />
                    </div>
                    <h2 id="modalTitle" className="modal__title" style={{ textAlign: 'center', marginBottom: "16px", marginTop: "16px" }}>Cosa sono gli OFA?</h2>
                    <p className="text-center">Se in una materia non raggiungi un certo punteggio al test ufficiale, potresti ottenere Obblighi Formativi Aggiuntivi (OFA).</p>
                    <p className="text-center">Il punteggio minimo ti permette di <img src={book_icon} className='inline-icon-small' /><strong className="text-tf">passare il modulo con gli OFA</strong>, ed è diverso tra le discipline!</p>
                    <p className="text-center">Per <img src={book_icon_grey} className='inline-icon-small' /><strong className="text-tf">passare senza OFA</strong>, dovrai rispondere correttamente a un numero maggiore di domande.</p>
                    <button id="closeModalButton" onClick={onCloseModal} className="mx-auto w-100 mt-4">
                        Chiudi
                    </button>
                </div>
            </Modal>
        </>
    );
}