import { useRef, useState } from "react";
import { Overlay } from "react-bootstrap";
import avatar_img from "../../assets/icons/avatar.svg";

import { useTranslation, Trans } from 'react-i18next';
import { prefixedT } from "../../i18n/utils";

export default function AvatarOverlay() {
  const target = useRef();
  const [show, setShow] = useState(true);

  const SaveToLocalStorage = function (key, obj) {
    return localStorage.setItem(key, JSON.stringify(obj));
  };

  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100vh",
        background: "rgba(0, 0, 0, 0.5)",
        display: show ? "block" : "none",
      }}
      onClick={() => {
        SaveToLocalStorage("avatar_show", { show: true });
        setShow(false);
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: 0,
          right: "10%",
          minHeight: "300px",
        }}
      >
        <img src={avatar_img} ref={target} />
        <Overlay target={target.current} show={show} placement="top">
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              {...props}
              style={{
                position: "absolute",
                backgroundColor: "#fff",
                padding: "16px 20px",
                color: "rgba(55, 55, 55, 0.75)",
                fontSize: 16,
                borderRadius: 16,
                maxWidth: "400px",
                boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
                ...props.style,
              }}
              className="tooltip_arrow bubble_left"
            >
              <Trans
                i18nKey={prefixedT("AVATARS.SIMULATOR_INTRO")}
                components={{
                  strong: <strong />,
                  br: <br />,
                }}
              />
            </div>
          )}
        </Overlay>
      </div>
    </div>
  );
}
