import { useRef, useState } from "react";
import "../../../_components/Question/Question.css";
import "./QuestionReview.css";
import { FormCheck, Overlay } from "react-bootstrap";
import "katex/dist/katex.min.css";

import Latex from "react-latex-next";

import user_answer_icon from "../../../assets/icons/user_icon.svg";
import uncertain_icon from "../../../assets/icons/uncertain_icon.svg";
import icon_correct_answer from "../../../assets/icons/answer_correct.svg";
import icon_wrong_answer from "../../../assets/icons/answer_wrong.svg";
import icon_missing_answer from "../../../assets/icons/answer_null.svg";
import icon_approfondimento from "../../../assets/icons/light.svg";
import error_uncertain_review_img from "../../../assets/icons/uncertain_wrong.svg";
import uncertain_review_icon from "../../../assets/icons/correct_uncertain.svg";
import icon_wip from "../../../assets/icons/wip.svg";

import ImageZoom from "../../../_components/ImageZoom/ImageZoom";
import error_review_img from "../../../assets/icons/error-review.svg";

import { useTranslation, Trans } from 'react-i18next';
import { prefixedT } from "../../../i18n/utils";

export default function QuestionReview(questionProps) {
  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef();

  const { t, i18n } = useTranslation();

  const formatText = (original_text = "") => {
    return original_text?.replaceAll("<p","< p")?.replaceAll("\n", "<br />")?.replaceAll("$$", "$");
  };

  return (
    <div className="col-9 exam_question_container px-4 pb-5">
      {questionProps.data && (
        <>
          <div className="d-flex align-items-center mb-2">
            {questionProps.data.incorrect_question && (
              <>
                {questionProps.data.was_incorrect &&
                  !questionProps.data.was_uncertain && (
                    <img
                      src={error_review_img}
                      alt="Sbagliata"
                      className="me-2 pointer"
                      ref={target}
                      onClick={() => setShowTooltip(!showTooltip)}
                    />
                  )}
                {questionProps.data.was_incorrect &&
                  questionProps.data.was_uncertain && (
                    <img
                      src={error_uncertain_review_img}
                      alt="Sbagliata ma non sicura"
                      className="me-2 pointer"
                      ref={target}
                      onClick={() => setShowTooltip(!showTooltip)}
                    />
                  )}
                {!questionProps.data.was_incorrect &&
                  questionProps.data.was_uncertain && (
                    <img
                      src={uncertain_review_icon}
                      alt="Non sicura ma corretta"
                      className="me-2 pointer"
                      ref={target}
                      onClick={() => setShowTooltip(!showTooltip)}
                    />
                  )}
                <Overlay
                  target={target.current}
                  show={showTooltip}
                  rootClose={true}
                  onHide={() => {
                    setShowTooltip(false);
                  }}
                  rootCloseEvent="click"
                  placement="bottom"
                >
                  {({
                    placement,
                    arrowprops,
                    show: _show,
                    popper,
                    ...props
                  }) => (
                    <div
                      {...props}
                      style={{
                        position: "absolute",
                        backgroundColor: "#fff",
                        padding: "16px 20px",
                        color: "rgba(55, 55, 55, 0.75)",
                        fontSize: 16,
                        borderRadius: 16,
                        maxWidth: "250px",
                        height: "max-content",
                        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
                        ...props.style,
                      }}
                      className={`tooltip_arrow_top ${
                        questionProps?.is_bocconi_design ? "bocconi" : ""
                      }`}
                    >
                      {questionProps.data.was_incorrect &&
                        !questionProps.data.was_uncertain && (
                          <>
                            {t(prefixedT("QUESTION.PAST_RESULT"))}
                            <img
                              src={error_review_img}
                              alt="incorrect"
                              className=""
                              style={{ maxWidth: "20px" }}
                            />
                            <strong className="text-tf">{t(prefixedT("QUESTION.ERROR"))}</strong>
                            {
                              t(prefixedT("QUESTION.PAST_RESULT_END"))
                            }
                          </>
                        )}
                      {questionProps.data.was_incorrect &&
                        questionProps.data.was_uncertain && (
                          <>
                            {t(prefixedT("QUESTION.PAST_ANSWER"))}
                            <img
                              src={error_review_img}
                              alt="incorrect"
                              className=""
                              style={{ maxWidth: "20px" }}
                            />
                            <strong className="text-tf">{t(prefixedT("QUESTION.ERROR"))}</strong>
                            {"e "}
                            <img
                              src={uncertain_icon}
                              alt="incorrect"
                              style={{ maxWidth: "20px" }}
                              className=""
                            />
                            <strong className="text-tf">
                              {t(prefixedT("QUESTION.UNSURE_ANSWER"))}
                            </strong>
                            {t(prefixedT("QUESTION.PAST_ANSWER_END"))}
                          </>
                        )}
                      {!questionProps.data.was_incorrect &&
                        questionProps.data.was_uncertain && (
                          <>
                            {t(prefixedT("QUESTION.PAST_ANSWER_CORRECT"))}
                            <img
                              src={uncertain_icon}
                              alt="incorrect"
                              style={{ maxWidth: "16px" }}
                              className=""
                            />
                            <strong className="text-tf">
                              {t(prefixedT("QUESTION.UNSURE_ANSWER"))}
                            </strong>
                            {t(prefixedT("QUESTION.PAST_ANSWER_END"))}
                          </>
                        )}
                    </div>
                  )}
                </Overlay>
              </>
            )}
            <p className=" mb-0">
              {`${t(prefixedT("QUESTION.QUESTION"))} ${questionProps.data.virtual_question_number}`}
              {questionProps.data.subject_name && (
                <span className="text-secondary">
                  {" "}
                  - ({questionProps.data.subject_name})
                </span>
              )}
            </p>
          </div>
          <p className="mt-3 question-text" style={{ whiteSpace: "pre-wrap" }}>
            <Latex>{formatText(questionProps.data.question)}</Latex>
          </p>
          {questionProps?.data?.paper &&
            questionProps.data.virtual_question_number !== undefined && (
              <div
                className="reader_preview"
                onClick={questionProps.handleOpenReader}
              >
                <p
                  style={{ whiteSpace: "pre-wrap" }}
                  dangerouslySetInnerHTML={{
                    __html: formatText(questionProps?.data?.paper),
                  }}
                />
              </div>
            )}
          {questionProps.data.image_url && (
            <div className="image_preview">
              <ImageZoom>
                <img
                  src={questionProps.data.image_url}
                  alt=""
                  className=" w-100"
                />
              </ImageZoom>
            </div>
          )}

          {!isNaN(questionProps.data.selected_answer) ? (
            <div className="answers_list">
              {questionProps.data.answers.map((answer) => {
                if (questionProps.data.selected_answer === answer.answer_number)
                  return (
                    <>
                      <div className="answer__title">
                        <img className="me-1" src={user_answer_icon} />
                        <span className="me-2">{t(prefixedT("REVIEW_SCREEN.YOUR_ANSWER"))}</span>(
                        {questionProps.data.is_uncertain ? (
                          <div className="d-flex align-items-center">
                            <img className="me-1" src={uncertain_icon} />
                            <span>{t(prefixedT("REVIEW_SCREEN.UNSURE_ANSWER"))}</span>
                          </div>
                        ) : (
                          <>
                            {answer.is_correct_answer ? (
                              <div className="d-flex align-items-center">
                                <img
                                  className="me-1"
                                  src={icon_correct_answer}
                                />
                                <span>{t(prefixedT("REVIEW_SCREEN.CORRECT"))}</span>
                              </div>
                            ) : (
                              <div className="d-flex align-items-center">
                                <img className="me-1" src={icon_wrong_answer} />
                                <span>{t(prefixedT("REVIEW_SCREEN.WRONG"))}</span>
                              </div>
                            )}
                          </>
                        )}
                        )
                      </div>
                      <div
                        className={
                          "answer " +
                          (answer.is_correct_answer
                            ? "correct_answer "
                            : "wrong_answer")
                        }
                      >
                        <label
                          className="label_inner"
                          htmlFor={`answer-${questionProps.data.question_number}-${answer.answer_number}`}
                        >
                          <FormCheck className="mb-0">
                            <FormCheck.Input
                              type={"radio"}
                              className="hidden_radio mb-0"
                              name={`answer-${questionProps.data.question_number}-${answer.answer_number}`}
                              id={`answer-${questionProps.data.question_number}-${answer.answer_number}`}
                              value={answer.answer_number}
                              checked={
                                answer.answer_number ===
                                questionProps.data.selected_answer
                              }
                              readOnly
                            />
                            <FormCheck.Label
                              className="mb-0"
                              htmlFor={`answer-${questionProps.data.question_number}-${answer.answer_number}`}
                            >
                              <span
                                className="answer_label_number"
                                style={{ color: "#FFFFFF66" }}
                              >
                                {String.fromCharCode(65 + answer.answer_number)}
                                .
                              </span>
                              <Latex strict>{formatText(answer.text)}</Latex>
                              {answer?.image_url && (
                                <img src={answer.image_url} />
                              )}
                            </FormCheck.Label>
                          </FormCheck>
                        </label>
                        {questionProps.data.is_uncertain && (
                          <div className="uncertain_selector">
                            <img src={uncertain_icon} />
                          </div>
                        )}
                      </div>
                    </>
                  );
                else return "";
              })}
            </div>
          ) : (
            <>
              <div className="answer__title">
                <img className="me-1" src={user_answer_icon} />
                <span className="me-2">{t(prefixedT("REVIEW_SCREEN.YOUR_ANSWER"))}</span>
              </div>
              <div className={"answer missing_answer"}>
                <label className="label_inner">
                  <label className="d-flex">
                    <img src={icon_missing_answer} />
                    <span className="ms-2">{t(prefixedT("REVIEW_SCREEN.MISSING_ANSWER"))}</span>
                  </label>
                </label>
              </div>
            </>
          )}
          {questionProps.data.selected_answer !== null && (
            <div className="answers_list">
              {questionProps.data.answers.map((answer) => {
                if (
                  questionProps.data.selected_answer !== answer.answer_number &&
                  answer.is_correct_answer
                )
                  return (
                    <>
                      <div className="answer__title">
                        <img className="me-1" src={icon_correct_answer} />
                        <span className="me-2">{t(prefixedT("REVIEW_SCREEN.CORRECT_ANSWER"))}</span>
                      </div>
                      <div
                        className={"answer correct_answer"}
                        key={answer.answer_number}
                      >
                        <label
                          className="label_inner"
                          htmlFor={`answer-${questionProps.data.question_number}-${answer.answer_number}`}
                        >
                          <FormCheck className="mb-0">
                            <FormCheck.Input
                              type={"radio"}
                              className="hidden_radio mb-0"
                              name={`answer-${questionProps.data.question_number}-${answer.answer_number}`}
                              id={`answer-${questionProps.data.question_number}-${answer.answer_number}`}
                              value={answer.answer_number}
                              checked={
                                answer.answer_number ===
                                questionProps.data.selected_answer
                              }
                              readOnly
                            />
                            <FormCheck.Label
                              className="mb-0"
                              htmlFor={`answer-${questionProps.data.question_number}-${answer.answer_number}`}
                            >
                              <span
                                className="answer_label_number"
                                style={{ color: "#FFFFFF66" }}
                              >
                                {String.fromCharCode(65 + answer.answer_number)}
                                .
                              </span>
                              <Latex strict>{formatText(answer.text)}</Latex>
                              {answer?.image_url && (
                                <img src={answer.image_url} />
                              )}
                            </FormCheck.Label>
                          </FormCheck>
                        </label>
                      </div>
                    </>
                  );
              })}
            </div>
          )}
          {questionProps.data.pill ? (
            <>
              <hr className="line" />
              <div className="answer__title">
                <img className="me-1" src={icon_approfondimento} />
                <span className="me-2">
                  {questionProps.data?.pill?.title ?? t(prefixedT("REVIEW_SCREEN.PILL_TITLE"))}
                </span>
              </div>
              <img
                className="mt-3"
                src={questionProps.data?.pill?.image_url}
                style={{ maxWidth: "350px" }}
              />
              <p className="mt-3" style={{ whiteSpace: "pre-wrap" }}>
                <Latex>{formatText(questionProps.data?.pill?.text)}</Latex>
              </p>
            </>
          ) : (
            <>
              <hr className="line" />
              <img
                className="mx-auto d-block"
                src={icon_wip}
                style={{ maxWidth: "24px" }}
              />
              <p
                className="mt-3 text-center"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {
                  t(prefixedT("REVIEW_SCREEN.PILL_WIP"))
                }
              </p>
            </>
          )}
        </>
      )}
    </div>
  );
}
