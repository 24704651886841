// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // Detects the user's language
import ChainedBackend from "i18next-chained-backend";
import HttpApi from 'i18next-http-backend'; // Import the http-backend for loading external resources
import resourcesToBackend from "i18next-resources-to-backend"; 

// Initialize i18n
i18n
    .use(ChainedBackend) // Use the http backend to load translations from a URL
    .use(LanguageDetector) // Automatically detect the user's language
    .use(initReactI18next) // Passes i18n instance to react-i18next
    .init({
        fallbackLng: 'it', // Default language if the detected language is not available
        interpolation: {
        escapeValue: false, // React already escapes the values to prevent XSS
        },
        detection: {
        // Order of language detection
        order: ['localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        
        // Cache the user's language in local storage
        caches: ['localStorage'],

        // Define how to detect the language from local storage
        lookupLocalStorage: 'i18nextLng', // The key in local storage where the language is stored
        },
        load:"languageOnly",
        backend: {
            backends: [
                HttpApi,
                resourcesToBackend((lng, ns) => import(`./locales/${lng}.json?`))
            ],
            backendOptions: [{
                loadPath: `${process.env.REACT_APP_I18N_JSON_URL}{{lng}}.json?v`+ (new Date()).getTime() // Path to your translation files
            }]
        }
    });

export default i18n;