import React, { useEffect, useState, useContext, useRef } from "react";
import { Overlay, OverlayTrigger, Popover, ProgressBar } from "react-bootstrap";
import { useTimer } from "react-timer-hook";

import Lottie from "lottie-react";
import timerAnimation from "../../assets/icons/timer.json";
import arrow_left from "../../assets/icons/arrow_previous.svg";
import arrow_right from "../../assets/icons/arrow_next.svg";
import switch_left from "../../assets/icons/switch_left_alert.svg";
import switch_right from "../../assets/icons/switch_right_alert.svg";
import switch_left_bocconi from "../../assets/icons/bocconi/switch_left_alert.svg";
import switch_right_bocconi from "../../assets/icons/bocconi/switch_right_alert.svg";

import "./Navigation.css";
import DisableConfirmModal from "../Modals/DisableConfirmModal";

import { useTranslation, Trans } from 'react-i18next';
import { prefixedT } from "../../i18n/utils";

export default function Navigation(props) {
  const expiryTime = new Date();

  const [selectedModule, setSelectedModule] = useState(0);
  const [showDisableConfirmModal, setShowDisableConfirmModal] = useState(false);
  const [showChangeQuestionTooltip, setShowChangeQuestionTooltip] = useState(false)

  const { t, i18n } = useTranslation();

  const { seconds, minutes, hours, days, restart } = useTimer({
    expiryTime,
    onExpire: () => {},
  });

  const questionNavRef = useRef()

  const seconds_ref = useRef(seconds);
  const minutes_ref = useRef(minutes);
  const hours_ref = useRef(hours);
  const days_ref = useRef(days);

  const changeQuestion = (index, module_number) => {
    if (!props?.navigationData?.is_linear_question_navigation){
      if (
        !props?.navigationData?.is_linear_module_navigation ||
        (props?.navigationData?.is_linear_module_navigation &&
          module_number === props?.navigationData?.current_module_number)
      )
        props?.handleChangeQuestion(index);
    } else {
      setShowChangeQuestionTooltip(true)
    }
  };

  const countMissing = () => {
    var count_missing = 0;
    props.questions.forEach((question) => {
      if (
        (props?.navigationData?.is_linear_module_navigation &&
          props?.navigationData?.current_module_number ===
            question.module_number) ||
        !props?.navigationData?.is_linear_module_navigation
      )
        count_missing =
          question.selected_answer === null || isNaN(question.selected_answer)
            ? count_missing + 1
            : count_missing;
    });
    return count_missing;
  };

  const askEndExam = () => {
    props?.handleCloseSimulation();
  };

  const formatTime = (time) => {
    return String(time).padStart(2, "0");
  };

  const renderColor = () => {
    let perc =
      ((days_ref.current * 86400 +
        hours_ref.current * 3600 +
        minutes_ref.current * 60 +
        seconds_ref.current) *
        100) /
      calculateTotalTime();
    if (perc > 40) return "";
    else if (perc > 20) return "warning";
    else return "danger";
  };

  const calculateTotalTime = () => {
    let total = 0;
    props?.questions?.forEach((q) => {
      if (
        (props?.navigationData?.is_linear_module_navigation &&
          props?.navigationData?.current_module_number === q?.module_number) ||
        !props?.navigationData.is_linear_module_navigation
      )
        total += parseInt(q.timeout);
    });
    return total;
  };

  const popoverTop = (
  <Popover id="popover-positioned-top" title="Popover top" style={{border:'0', boxShadow:'0px 0px 12px 0px #7B7B7B66'}}>
    <div className="p-3">
      <Trans
        i18nKey={prefixedT("NAVIGATION.BLOCKED_NAVIGATION")}
        components={{
          strong: <strong />,
          br: <br />,
        }}
      />
    
    </div>                
  </Popover>
);

  useEffect(() => {
    seconds_ref.current = seconds;
    minutes_ref.current = minutes;
    hours_ref.current = hours;
    days_ref.current = days;
  }, [seconds]);

  useEffect(() => {
    let time = new Date();
    time.setSeconds(time.getSeconds() + props?.remaining_time);
    restart(time);
    props?.saveEndDate(time);
  }, [props?.remaining_time]);

  useEffect(() => {
    setSelectedModule(props?.navigationData?.current_module_number);
  }, [props?.navigationData?.current_module_number]);

  return (
    <div className="col-3 navigation_bar pb-5">
      <div className="d-flex align-items-top justify-content-start">
        <Lottie
          animationData={timerAnimation}
          autoplay={true}
          loop={100000}
          style={{ width: "35px" }}
          className="d-block me-2"
        />
        <div className={"exam_timer " + "text-" + renderColor()}>
          <span>{formatTime(hours + (days*24))}</span>:<span>{formatTime(minutes)}</span>:
          <span>{formatTime(seconds)}</span>
        </div>
      </div>
      <ProgressBar
        className="time_pbar"
        now={
          ((days_ref.current * 86400 +
            hours_ref.current * 3600 +
            minutes_ref.current * 60 +
            seconds_ref.current) *
            100) /
          calculateTotalTime()
        }
      />
      <button className="mt-5 mb-4" onClick={askEndExam}>
        {props.simulationType === "incorrect_questions" ? (
          <>{t(prefixedT("NAVIGATION.END_REVIEW"))}</>
        ) : (
          <>
            {!props.navigationData.is_linear_module_navigation
              ? props?.isBocconiDesign
                ? t(prefixedT("NAVIGATION.END_PRACTICE"))
                : t(prefixedT("NAVIGATION.END_SIMULATION"))
              : `${t(prefixedT("NAVIGATION.END_MODULE"))} ${
                  props.navigationData.current_module_number + 1
                }`}
          </>
        )}
      </button>
      {props.navigationData.is_linear_module_navigation && (
        <div className="mb-0">
          <p className="text-black mb-0" style={{ fontWeight: "800" }}>
            {`${t(prefixedT("NAVIGATION.MODULE"))} ${props.navigationData.current_module_number + 1}: ${
              props.navigationData.modules[
                props.navigationData?.current_module_number
              ]?.name
            }`}
          </p>
        </div>
      )}
      <div className="">
        <p className="text-secondary" style={{ fontSize: "14px" }}>
          {`${t(prefixedT("NAVIGATION.MISSING_ANSWERS"))}: ${countMissing()}`}
        </p>
      </div>
      <OverlayTrigger
        placement="top"
        trigger="click"
        overlay={props?.navigationData?.is_linear_question_navigation ? popoverTop : <></>}
        
      >
      {props?.navigationData?.is_linear_module_navigation ? (
        <div className="mt-3">
          {props?.navigationData?.current_module_number ? (
            <div className="d-flex w-100 justify-content-between">
              <div
                className="d-flex pointer"
                onClick={() => {
                  setSelectedModule(selectedModule - 1);
                }}
                style={{
                  visibility: selectedModule !== 0 ? "visible" : "hidden",
                }}
              >
                <img className="me-1" src={arrow_left} />
                <span>{`${t(prefixedT("NAVIGATION.MODULE"))} ${selectedModule}`}</span>
              </div>
              <div
                className="d-flex pointer"
                onClick={() => {
                  setSelectedModule(selectedModule + 1);
                }}
                style={{
                  visibility:
                    selectedModule + 1 < props?.navigationData?.modules?.length
                      ? "visible"
                      : "hidden",
                }}
              >
                <span>{`${t(prefixedT("NAVIGATION.MODULE"))} ${selectedModule + 2}`}</span>
                <img className="ms-1" src={arrow_right} />
              </div>
            </div>
          ): null}
          <div className="mt-3 questions_nav">
            {props?.questions.map((question, index) => {
              let has_answer =
                question.selected_answer !== null &&
                !isNaN(question.selected_answer);
              if (question.module_number === selectedModule)
                return (
                  <div
                    key={index}
                    className={
                      "question_el_external " +
                      (question.isBlockQuestion ? "block_wrapped " : "") +
                      (question.isFirstBlock ? "block_wrapped_first " : "") +
                      (question.isLastBlock ? "block_wrapped_last " : "")
                    }
                  >
                    <div
                      key={index}
                      onClick={() => {
                        changeQuestion(
                          question.question_id,
                          question.module_number
                        );
                      }}
                      className={
                        "question_el " +
                        (has_answer ? "answered " : "") +
                        (question.is_uncertain ? "is_uncertain " : "") +
                        (question.question_id === props.currentQuestion
                          ? "current "
                          : "") +
                        (props.navigationData.is_linear_module_navigation &&
                        props.navigationData?.current_module_number !==
                          question.module_number
                          ? "disabled_question "
                          : "") +
                        (index > 98 && index < 999 ? "small " : "") +
                        (index > 999 ? "xsmall " : "")
                      }
                    >
                      {parseInt(index) + 1}
                    </div>
                  </div>
                );
              else return "";
            })}
          </div>
        </div>
      ) : (
        <div className="mt-4 questions_nav">
          {props.questions.map((question, index) => {
            let has_answer =
              question.selected_answer !== null &&
              !isNaN(question.selected_answer);
            return (
              <div
                key={index}
                className={
                  "question_el_external " +
                  (question.isBlockQuestion ? "block_wrapped " : "") +
                  (question.isFirstBlock ? "block_wrapped_first " : "") +
                  (question.isLastBlock ? "block_wrapped_last " : "")
                }
              >
                <div
                  key={index}
                  onClick={() => {
                    changeQuestion(
                      question.question_id,
                      question.module_number
                    );
                  }}
                  className={
                    "question_el " +
                    (has_answer ? "answered " : "") +
                    (question.is_uncertain ? "is_uncertain " : "") +
                    (question.question_id === props.currentQuestion
                      ? "current "
                      : "") +
                    (props?.navigationData?.is_linear_question_navigation &&
                    parseInt(question.question_id) <
                      parseInt(props.currentQuestion)
                      ? "disabled_question "
                      : "") +
                    (index > 98 && index < 999 ? "small " : "") +
                    (index > 999 ? "xsmall " : "")
                  }
                >
                  {parseInt(index) + 1}
                </div>
              </div>
            );
          })}
        </div>
      )}
      </OverlayTrigger>
      { 
        props?.navigationData?.is_linear_question_navigation &&
        <div className="linear_toggle">
          <img
            onClick={() => {
              if (props?.showConfirmPopup) {
                setShowDisableConfirmModal(true);
                return;
              }
              props?.setShowConfirmPopup(!props?.showConfirmPopup);
            }}
            className="switch_icon"
            src={
              props?.showConfirmPopup
                ? props?.isBocconiDesign
                  ? switch_right_bocconi
                  : switch_right
                : props?.isBocconiDesign
                ? switch_left_bocconi
                : switch_left
            }
          />
          <div className="switch_text">{t(prefixedT("NAVIGATION.ANSWER_CONFIRMATION"))}</div>
        </div>
      }
        <DisableConfirmModal
          show={showDisableConfirmModal}
          handleConfirm={() => {
            props?.setShowConfirmPopup(!props?.showConfirmPopup);
          }}
          handleClose={() => {
            setShowDisableConfirmModal(false);
          }}
          isBocconiDesign={props?.isBocconiDesign}
        />
    </div>
  );
}
