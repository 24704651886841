export const normalizeQuestions = (questions) => {
  let _questions = [];

  let counter = 1;

  questions.forEach((question) => {
    if (question.structure === "standard") {
      _questions.push({
        blocked_question: question.question && question.answers ? false : true,
        ...question,
        question_id: `${question.question_number}`,
        virtual_question_number: counter > question?.question_number ? counter : question?.question_number + 1,
      });
      counter += 1;
    }
    if (question.structure === "block") {
      const blocks = question.blocks ?? [];

      blocks.forEach((block, idx) => {
        const isFirstBlock = idx === 0;
        const isLastBlock = idx === blocks?.length - 1;
        const blockQuestion = {
          ...question,
          ...block,
          isFirstBlock,
          isLastBlock,
          isBlockQuestion: true,
          blocked_question: block.question && block.answers ? false : true,
          question_id: `${question.question_number}_${block.block_number}`,
          virtual_question_number: counter > question?.question_number ? counter : question?.question_number + 1,
        };
        delete blockQuestion.blocks;
        _questions.push(blockQuestion);
        counter += 1;
      });
    }
  });

  return _questions;
};

export const extractPapers = (questions) => {
  let _papers = [];

  questions.forEach((question) => {
    if (question.structure === "block") {
      const blocks = question.blocks ?? [];

      _papers.push({
        paper: question?.paper,
        question_number: question?.question_number,
        fontSize: 20,
        selected_text: [],
      });
    } else if (
      question.paper !== null &&
      question.paper !== undefined &&
      question.paper !== ""
    ) {
      _papers.push({
        paper: question.paper,
        question_number: question.question_number,
        fontSize: 20,
        selected_text: [],
      });
    }
  });

  console.log("_papers", _papers);
  return _papers;
};
