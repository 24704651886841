import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SimulationContext,
  QuestionPapersContext,
} from "../../context/SimulationContext";
import Controller from "../../_components/Controller/Controller";
import Header from "../../_components/TopBar/TopBar";
import NavigationReview from "./_components/NavigationReview";
import QuestionReview from "./_components/QuestionReview";

import "./../SimulationScreen/SimulationScreen.css";
import PaperReader from "../../_components/Question/PaperReader";
import { extractPapers } from "../../utils/questionUtils";

export default function QuestionRevisionScreen() {
  const [isLoaded, setIsLoaded] = useState(false);
  const { simulationData } = useContext(SimulationContext);
  const { questionPapersData, setQuestionPapersData } = useContext(QuestionPapersContext);
  const [currentQuestion, setCurrentQuestion] = useState(
    simulationData?.questions[0]?.question_id
  );
  const [globalEndDate, setGlobalEndDate] = useState(0);
  const [openReader, setOpenReader] = useState(false);

  // Navigation Handler
  const handleChangeQuestion = (question_index) => {
    setCurrentQuestion(question_index);
  };

  const handleOpenReader = () => {
    setOpenReader(true);
  };

  const handleCloseReader = () => {
    setOpenReader(false);
  };

  const getPaper = () => {
    const _realPaper = questionPapersData?.find( (p) => p.question_number ===
                simulationData.questions?.find(
                  (q) => q.question_id === currentQuestion
                )?.question_number
          )
    if(_realPaper)
        return _realPaper
    else if(simulationData.questions?.find( (q) => q.question_id === currentQuestion)?.paper){
        let questionPapers = extractPapers(
              [simulationData.questions?.find( (q) => q.question_id === currentQuestion)] || [] );
        setQuestionPapersData([...questionPapersData || [], ...questionPapers]);
        return questionPapers[0]
    } else  
      return null
  }

  useEffect(() => {
    console.log(simulationData);
    setIsLoaded(true);
  }, []);

  return (
    <div className="simulation_screen">
      <Header
        university_logo={simulationData && simulationData.admission_test}
        isBocconiDesign={simulationData?.is_bocconi_design}
      />
      {isLoaded && simulationData && (
        <>
          {openReader && (
            <PaperReader
              isEnded={true}
              expiryTime={globalEndDate}
              data={
                simulationData.questions?.find(
                  (q) => q.question_id === currentQuestion
                ) || null
              }
              paperData={
               getPaper()
              }
              handleCloseReader={handleCloseReader}
              isBocconiDesign={simulationData?.is_bocconi_design}
            />
          )}
          <section id="examContent">
            <div className="row ">
              <NavigationReview
                questions={simulationData.questions}
                navigationData={simulationData.navigation}
                handleChangeQuestion={handleChangeQuestion}
                currentQuestion={currentQuestion}
                remaining_time={simulationData.remaining_time}
                isBocconiDesign={simulationData.is_bocconi_design}
              />
              <QuestionReview
                data={simulationData.questions?.find(
                  (q) => q.question_id === currentQuestion
                )}
                university_logo={
                  simulationData && simulationData.admission_test
                }
                currentQuestion={currentQuestion}
                handleOpenReader={handleOpenReader}
                isBocconiDesign={simulationData?.is_bocconi_design}
              />
            </div>
          </section>
          <Controller
            currentQuestion={currentQuestion}
            handleChangeQuestion={handleChangeQuestion}
            university_logo={simulationData && simulationData.admission_test}
            questions={simulationData.questions}
            isBocconiDesign={simulationData?.is_bocconi_design}
            isReviewScreen={true}
          />
        </>
      )}
    </div>
  );
}
