import { useEffect, useContext, useState, useRef } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import logo_tf from "../../assets/icons/logo_menu_blue.svg";
import logo_selexi from "../../assets/icons/logo_selexi.png";
import logo_test from "../../assets/icons/test_section.svg";
import check from "../../assets/icons/check-certified.svg";
import qrApp from "../../assets/backgrounds/qr-code.png";

import "./InitialScreen.css";
import TopBar from "../../_components/TopBar/TopBar";
import ErrorModal from "../../_components/Modals/ErrorModal";
import { useTranslation, Trans } from 'react-i18next';

// Import Swiper React components
import { register } from 'swiper/element/bundle';
import { prefixedT } from "../../i18n/utils";

export default function InitialScreen() {
  const [isLoaded, setIsLoaded] = useState(true);
  const [userPin, setUserPin] = useState("");
  const [errorModalDetails, setErrorModalDetails] = useState({
    show: false,
    text: "",
    title: "",
  });
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  let swiperRef = useRef()

  const [sposorList, setSponsorsList] = useState([])

  const loadSponsors = async () => {
    try{
      const response = await fetch(process.env.REACT_APP_SPONSORS_API)
      const sponsors = await response.json();
      if(sponsors.success){
        setSponsorsList(sponsors.data)
        register();

        // Object with parameters
        const params = {
          slidesPerView: 3,
          autoplay: true,
          spaceBetween: 30,
          loop: true
        };

        // Assign it to swiper element
        Object.assign(swiperRef.current, params);

        // initialize swiper
        swiperRef.current.initialize();
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(()=>{
    loadSponsors()
  },[])

  const editPin = (event) => {
    setUserPin(event.target.value.toUpperCase());
  };

  const handleCloseErrorModal = () => {
    setErrorModalDetails({
      ...errorModalDetails,
      show: false,
    });
  };

  const submitUserPin = async (e) => {
    setIsLoaded(false);
    e.preventDefault();
    if (userPin && userPin.length >= 6) {
      navigate("/simulation", { replace: true, state: { userPin: userPin } });
    } else
      setErrorModalDetails({
        ...errorModalDetails,
        show: true,
        title: t(prefixedT("ERRORS.WRONG_PIN")),
        text: t(prefixedT("ERRORS.WRONG_PIN_TEXT")),
      });
  };

  return (
    <div id="login_screen">
      {!isLoaded ? <div className="loader"></div> : ""}
      <TopBar />
      <div className="row mt-3 justify-content-center d-none d-md-flex" style={{zIndex:'100', position:'relative'}}>
        <div className="col-md-8 col-lg-6 p-3">
          <img src={logo_test} className="mx-auto section-icon" />
          <h1 className="text-center mt-2">{t(prefixedT("LOGIN_SCREEN.CONTINUE_SIMULATION_TITLE"))}</h1>
          <p className="text-center mt-3">
            <Trans
              i18nKey={prefixedT("LOGIN_SCREEN.CONTINUE_SIMULATION_TEXT")}
              components={{
                strong: <strong />,
                br: <br />,
              }}
            />
          </p>
          <form onSubmit={submitUserPin} style={{ zIndex: "10" }}>
            <div className="form-group">
              <input
                type="text"
                value={userPin}
                className="form-control"
                onChange={editPin}
                placeholder={t(prefixedT("LOGIN_SCREEN.INPUT_PLACEHOLDER_PIN"))}
                min={6}
                required
              />
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                id="loginButton"
                className="mt-2"
                disabled={!userPin || userPin.length < 6}
              >
                {t(prefixedT("LOGIN_SCREEN.CONTINUE_SIMULATION"))}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="row mt-3 justify-content-center d-flex d-md-none">
        <div className="col-10 p-3 pt-5">
          <img src={logo_test} className="mx-auto section-icon" />
          <h1 className="text-center mt-2">
            {t(prefixedT("LOGIN_SCREEN.MOBILE_TITLE"))}
          </h1>
          <p className="text-center mt-3">
            {t(prefixedT("LOGIN_SCREEN.MOBILE_TEXT"))}
          </p>
          <a href="https://tfly.app/open">
            <button type="button" className="mt-2 mx-auto">
              {t(prefixedT("BUTTONS.OPEN_APP"))}
            </button>
          </a>
        </div>
      </div>
      <div className="footer_info">
        <div className="university_carousel">
          <swiper-container
           init="false" 
           ref={swiperRef}
           style={{alignItems: 'center'}}
          >
            {
              sposorList?.map((s,i)=>{
                if(s.is_sponsor)
                  return(
                    <swiper-slide key={i}><img src={s.university_image_url} className="w-100" /></swiper-slide>
                  )
              })
            }
          </swiper-container>
        </div>
        <div id="qr-box" className={'d-none d-lg-block ' + (i18n.language === 'en' || i18n.language === 'en-EN' ? 'en' : '')}>
          <div className="box">
          </div>
        </div>
      </div>
      <ErrorModal
        show={errorModalDetails.show}
        title={errorModalDetails.title}
        text={errorModalDetails.text}
        handleClose={handleCloseErrorModal}
      />
    </div>
  );
}
