import React, { useEffect, useRef, useContext } from "react";
import { SimulationContext } from "../../context/SimulationContext";
import { useTimer } from "react-timer-hook";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import icon_correct_answer from "../../assets/icons/answer_correct.svg";
import icon_wrong_answer from "../../assets/icons/answer_wrong.svg";
import icon_missing_answer from "../../assets/icons/answer_null.svg";
import info_icon from "../../assets/icons/info_icon.svg";
import close_icon from "../../assets/icons/close.svg";
import question_icon from "../../assets/icons/questions_review.svg";
import time_icon from "../../assets/icons/result_time.svg";

import { useTranslation, Trans } from 'react-i18next';
import { prefixedT } from "../../i18n/utils";

export default function ModuleWaitingScreen(props) {
  const { simulationData } = useContext(SimulationContext);
  const { t, i18n } = useTranslation();

  const { seconds, minutes, hours, restart } = useTimer({
    expiryTimestamp: simulationData.remaining_time,
    onExpire: () => {},
  });
  const seconds_ref = useRef(seconds);
  const minutes_ref = useRef(minutes);
  const hours_ref = useRef(hours);

  useEffect(() => {
    seconds_ref.current = seconds;
    minutes_ref.current = minutes;
    hours_ref.current = hours;
  }, [seconds]);

  useEffect(() => {
    let time = new Date();
    time.setSeconds(time.getSeconds() + simulationData.remaining_time);
    restart(time);
  }, [simulationData.remaining_time]);

  return (
    <div className="module_waiting_screen">
      <div className="icon-close-relative ">
        <img
          src={close_icon}
          onClick={props?.handleCloseSimulation}
          className="pointer"
        />
      </div>
      <div className="row justify-content-center mt-4">
        <div className="col-6">
          <img
            src={
              simulationData.navigation?.modules[
                simulationData?.navigation?.current_module_number
              ]?.image_url
            }
            className="module_icon"
          />
          <h1 className="text-center">
            {`${t(prefixedT('MODULE_WAITING_SCREEN.MODULE'))} ${
              simulationData?.navigation?.current_module_number + 1
            } ${t(prefixedT('MODULE_WAITING_SCREEN.OF'))} ${simulationData?.navigation?.modules?.length}`}
          </h1>
          <p className="text-secondary text-center">
            {
              simulationData.navigation?.modules[
                simulationData?.navigation?.current_module_number
              ]?.name
            }
          </p>
          <p className="text-secondary text-center mt-4">
            {
              simulationData.navigation?.modules[
                simulationData?.navigation?.current_module_number
              ]?.description
            }
          </p>
          <div className="d-flex justify-content-center ">
            <div className="align-items-center d-flex">
              <p className="mb-0">
                <strong>
                  {
                    simulationData?.disciplines_scoring[
                      simulationData?.questions?.find(
                        (q) =>
                          q.module_number ===
                          simulationData?.navigation?.current_module_number
                      )?.discipline_id
                    ]?.correct
                  }
                </strong>
              </p>
              <img className="ms-1" src={icon_correct_answer} />
            </div>
            <div className="align-items-center d-flex ms-4 me-4">
              <p className="mb-0">
                <strong>
                  {
                    simulationData?.disciplines_scoring[
                      simulationData?.questions?.find(
                        (q) =>
                          q.module_number ===
                          simulationData?.navigation?.current_module_number
                      )?.discipline_id
                    ]?.wrong
                  }
                </strong>
              </p>
              <img className="ms-1o" src={icon_wrong_answer} />
            </div>
            <div className="align-items-center d-flex">
              <p className="mb-0">
                <strong>
                  {
                    simulationData?.disciplines_scoring[
                      simulationData?.questions?.find(
                        (q) =>
                          q.module_number ===
                          simulationData?.navigation?.current_module_number
                      )?.discipline_id
                    ]?.missing
                  }
                </strong>
              </p>
              <img className="ms-1 " src={icon_missing_answer} />
            </div>
          </div>
          <div className="d-flex justify-content-between mt-5 align-items-center">
            <div>
              <div className="d-flex justify-content-center align-items-center">
                <img src={time_icon} alt="Tempo" />
                <p className="mb-0 ms-1">{t(prefixedT('MODULE_WAITING_SCREEN.AVAILABLE_TIME'))}</p>
              </div>
              <span className="text-center text-bold-h1 d-block">
                {Math.round(
                  simulationData.navigation?.modules[
                    simulationData?.navigation?.current_module_number
                  ]?.timeout / 60
                )}{" "}
                min
              </span>
            </div>
            <div>
              <div className="d-flex justify-content-center align-items-center">
                <img src={question_icon} alt="Tempo" />
                <p className="mb-0 ms-1">{t(prefixedT('MODULE_WAITING_SCREEN.QUESTIONS_COUNT'))}</p>
              </div>
              <span className="text-center text-bold-h1 d-block">
                {
                  simulationData.navigation?.modules[
                    simulationData?.navigation?.current_module_number
                  ]?.questions_count
                }
              </span>
            </div>
          </div>
          <div className="page_bottom px-5">
            <div className="mx-auto" style={{ width: "40px" }}>
              <CircularProgressbar
                counterClockwise={true}
                maxValue={100}
                value={
                  ((minutes_ref.current * 60 + seconds_ref.current) * 100) / 300
                }
                styles={buildStyles({
                  pathColor: "#296D9F",
                  trailColor: "#ECECEC",
                  backgroundColor: "transparent",
                })}
              />
            </div>

            <p className="text-center mt-2">
              {
                t(prefixedT('MODULE_WAITING_SCREEN.AUTO_START_TEXT'),{module_number:simulationData?.navigation?.current_module_number + 1})
              }
            </p>
            <div className={"text-center text-timer "}>
              <span>{minutes}</span> min <span>{seconds}</span> s
            </div>
            <div className="bottom_banner_box mt-4">
              <img className="mx-auto d-block" src={info_icon} />
              <p className="mt-2 text-center">
                {
                  t(prefixedT('MODULE_WAITING_SCREEN.MODULE_ALERT'))
                }
              </p>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  onClick={props.startModule}
                  className="mt-3 mb-3"
                >
                  {t(prefixedT('MODULE_WAITING_SCREEN.START_MODULE'),{module_number:simulationData?.navigation?.current_module_number + 1})}
                  
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
