import React, { useEffect } from "react";
import Modal from "react-modal";
import icon from "../../assets/icons/flag.svg";

import { useTranslation, Trans } from 'react-i18next';
import { prefixedT } from "../../i18n/utils";

import "./Modal.css";

export default function EndExamModal(props) {
  const onCloseModal = () => {
    props?.handleClose();
  };

  const confirmClose = () => {
    props.handleConfirm();
  };

  const { t, i18n } = useTranslation();

  const style = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: 10010,
    },
    content: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      maxWidth: "350px",
      border: "0",
      background: "#fff",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      borderRadius: "24px",
      outline: "none",
      padding: "0",
    },
  };

  const countMissing = () => {
    var count_missing = 0;
    props.questions.forEach((question) => {
      if (
        (props?.navigationData?.is_linear_module_navigation &&
          props?.navigationData?.current_module_number ===
            question.module_number) ||
        !props?.navigationData?.is_linear_module_navigation
      )
        count_missing =
          question.selected_answer === null || isNaN(question.selected_answer)
            ? count_missing + 1
            : count_missing;
    });
    return count_missing;
  };

  useEffect(() => {}, [props]);

  return (
    <>
      <Modal
        closeTimeoutMS={300}
        isOpen={props.show}
        contentLabel="modal"
        onRequestClose={onCloseModal}
        className={props?.isBocconiDesign ? "bocconi" : "center_modal"}
        overlayClassName="bottom_modal_overlay"
        ariaHideApp={false}
        style={style}
      >
        <div className="modal-content">
          <div className="d-flex justify-content-center">
            <img
              alt="Sei sicuro?"
              className="noselect header_image"
              src={icon}
            />
          </div>
          {props?.endModuleOnly ? (
            <h2
              id="modalTitle"
              className="modal__title"
              style={{
                textAlign: "center",
                marginBottom: "16px",
                marginTop: "16px",
              }}
            >
              {`${t(prefixedT("MODALS.END_MODULE"))} ${
                props?.navigationData?.current_module_number + 1
              }?`}
            </h2>
          ) : (
            <>
              {props.simulationType === "incorrect_questions" ? (
                <h2
                  id="modalTitle"
                  className="modal__title"
                  style={{
                    textAlign: "center",
                    marginBottom: "16px",
                    marginTop: "16px",
                  }}
                >
                  {t(prefixedT("MODALS.END_REVIEW"))}
                </h2>
              ) : (
                <h2
                  id="modalTitle"
                  className="modal__title"
                  style={{
                    textAlign: "center",
                    marginBottom: "16px",
                    marginTop: "16px",
                  }}
                >
                  {props?.isBocconiDesign
                    ? t(prefixedT("MODALS.END_PRACTICE"))
                    : t(prefixedT("MODALS.END_SIMULATION"))}
                </h2>
              )}
            </>
          )}

          <div style={{ textAlign: "center", marginBottom: "24px" }}>
            <p className="text-secondary">
              {t(prefixedT("MODALS.MISSING_ANSWERS"),{missing_answers:countMissing()})}
            </p>
          </div>
          <div className="">
            {props.endModuleOnly ? (
              <button
                id="closeModalButton"
                onClick={props.endModule}
                className="w-100"
              >
                {t(prefixedT("BUTTONS.END_MODULE"))}
              </button>
            ) : (
              <>
                {props.simulationType === "incorrect_questions" ? (
                  <button
                    id="closeModalButton"
                    onClick={confirmClose}
                    className="w-100"
                  >
                    {t(prefixedT("BUTTONS.END_REVIEW"))}
                  </button>
                ) : (
                  <button
                    id="closeModalButton"
                    onClick={confirmClose}
                    className="w-100"
                  >
                    {props?.isBocconiDesign
                      ? t(prefixedT("BUTTONS.END_PRACTICE"))
                      : t(prefixedT("BUTTONS.END_SIMULATION"))}
                  </button>
                )}
              </>
            )}
            <button
              id="closeModalButton"
              onClick={onCloseModal}
              className="mt-2 white w-100"
            >
              {t(prefixedT("BUTTONS.DISCARD"))}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
