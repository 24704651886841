
import { ProgressBar } from "react-bootstrap";

import thumb_up from '../../assets/icons/thumb-up.svg'
import thumb_down from '../../assets/icons/thumb-down.svg'

export default function ReducedResultGraph({data}){
    return(
        <>
            {data?.map((r,key)=>{
                return(
                    <div className="text-center result_group mt-2" key={key}>
                        {console.log(r)}
                        <img style={{maxWidth:"20px"}} src={r.discipline_image_url} alt=""/>
                        <p>{r.discipline_name}</p>
                        <div className=" mx-auto d-flex align-items-center justify-content-between">
                            <ProgressBar className="mx-auto result_bar" max={r.correct+r.missing+r.wrong} now={r.correct} />
                        </div>
                        <p className="mt-2">{r.correct}/{r.correct+r.missing+r.wrong}</p>
                    </div>
                )
            })}
        </>
    )
}