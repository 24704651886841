import React, { useEffect, useState } from 'react';

export function ResultGraph(props) {
  const [graphUrl, setGrapUrl] = useState("");

  useEffect(()=>{
      let disciplines_score = props.data ?? [];
      let _graphResultData = [];
      let tempData = disciplines_score?.sort(function (a, b) {
        return b?.discipline_name?.length - a?.discipline_name?.length;
      });

      if (tempData?.length === 5) {
        let t = tempData[2];
        tempData[2] = tempData[4];
        tempData[4] = t;
      }

      tempData?.forEach(d => {
        let value = (d?.total_score / d?.max_score) * 100;
        value = value > 0 ? value : 0.05;
        _graphResultData.push({
          label: d?.discipline_name,
          urlImage: d?.discipline_image_url,
          value,
        });
      });
      setGrapUrl("/static/graph_template.html?data="+(JSON.stringify(_graphResultData)));
  },[props.data])

  return(
    <iframe src={graphUrl} sandbox="allow-same-origin allow-scripts"  style={{width:"100%", height:"100%", minHeight:"400px", overflow:"visible"}}/>
  )
  
  ;
}