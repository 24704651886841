import { useEffect, useState, useContext, useRef } from "react";
import {
  SimulationContext,
  QuestionPapersContext,
} from "../context/SimulationContext";
import * as gtag from "..//utils/gtag";
import { normalizeQuestions, extractPapers } from "../utils/questionUtils";
import { useTranslation } from "react-i18next";
import { prefixedT } from "../i18n/utils";

const WsHelper = (
  socketRef,
  handleSetSimulationData,
  handleEndSimulation,
  throwSocketError,
  handleChangeQuestion
) => {
  const { simulationData, setSimulationData } = useContext(SimulationContext);
  const { questionPapersData, setQuestionPapersData } = useContext(
    QuestionPapersContext
  );
  const [intervalId, setIntervalId] = useState(null);

  const {t, i18n} = useTranslation()
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language
    localStorage.setItem('i18nextLng', lng); // Save the language to local storage
  };

  const createConnection = (pin) => {
    if (pin)
      startWebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}${pin}/websocket`,
        false,
        true
      );
  };

  const closeSocket = () => {
    console.log("Socket closeSocket");
    socketRef.current?.close();
  };

  useEffect(() => {
    dataRef.current = simulationData;
  }, [simulationData]);

  useEffect(() => {
    paperDataRef.current = questionPapersData;
  }, [questionPapersData]);

  const dataRef = useRef(simulationData);
  const paperDataRef = useRef(questionPapersData);

  const handleUpdateQuestionData = (question_id, data) => {
    let qs = dataRef?.current?.questions?.slice();
    for (let i in qs) {
      if (qs[i].question_id === question_id) {
        qs[i]["selected_answer"] = data.answer_number;
        qs[i]["is_uncertain"] = data.is_uncertain;
        qs[i]["is_sending_answer"] = false;
        setSimulationData({
          ...dataRef.current,
          questions: qs,
        });
        break;
      }
    }
  };

  const calculateTotalTime = () => {
    let total = 0;
    dataRef.current.questions.forEach((q) => {
      total += parseInt(q.timeout);
    });
    return total;
  };

  const sendToWebSocket = (message) => {
    if (socketRef.current?.readyState === WebSocket.OPEN) {
      socketRef.current?.send(message);
    } else {
      startWebSocket(socketRef.current?.url);
      setTimeout(() => {
        sendToWebSocket(message);
      }, 2000);
    }
  };

  const startCheckConnection = () => {
    if (
      socketRef.current?.readyState !== WebSocket.CLOSING &&
      socketRef.current?.readyState !== WebSocket.CLOSED
    ) {
      let interval = setTimeout(function () {
        if (
          socketRef.current?.readyState === WebSocket.CLOSING ||
          socketRef.current?.readyState === WebSocket.CLOSED
        ) {
          console.warn("restart connection");
          startWebSocket(socketRef.current?.url, true);
        } else {
          startCheckConnection();
        }
      }, 60000);
    }
  };

  const startWebSocket = (webSocketUrl, is_retry = false, is_login = false) => {
    try {
      console.log("Run startWebSocket");
      socketRef.current = new WebSocket(webSocketUrl);
      socketRef.current.onopen = () => {
        console.log("Socket onOpen");
        startCheckConnection();
      };
      socketRef.current.onclose = (e) => {
        console.log("Socket onClose");
        clearInterval(intervalId);
      };
      socketRef.current.onmessage = (event) => {
        const dataParsed = JSON.parse(event.data);
        const { event: eventName, data } = dataParsed;
        console.log("Socket onMessage", { eventName, data });
        switch (eventName) {
          case "current_state": {
            let payload = data || null;

            //Firstly check language
            if(payload?.user_data?.language)
              changeLanguage(payload?.user_data?.language)
            

            let questionPapers = extractPapers(payload?.questions || []);
            payload.questions = normalizeQuestions(payload?.questions || []);
            payload.is_bocconi_design =
              process.env.REACT_APP_BOCCONI_ADMISSION_TESTS_IDS?.split(
                ","
              ).includes(payload?.admission_test?.admission_test_id);
            payload.is_med_design =
            process.env.REACT_APP_MED_ADMISSION_TESTS_IDS?.split(
              ","
            ).includes(payload?.admission_test?.admission_test_id);
            payload.is_vet_design =
            process.env.REACT_APP_VET_ADMISSION_TESTS_IDS?.split(
              ","
            ).includes(payload?.admission_test?.admission_test_id);
            setQuestionPapersData(questionPapers);
            gtag.event({
              action: "started_web_simulation",
              category: "Simulation Started",
              label: "Simulation Started",
              value: payload.simulation_id,
            });
            handleSetSimulationData(payload);
            if (
              payload?.navigation?.is_linear_module_navigation &&
              payload?.navigation?.is_module_active
            ) {
              handleChangeQuestion(
                payload?.navigation?.current_block_number !== null
                  ? `${payload?.navigation?.current_question_number}_${payload?.navigation?.current_block_number}`
                  : `${payload?.navigation?.current_question_number}`
              );
            } else if (!payload?.navigation?.is_linear_module_navigation) {
              if (payload?.navigation?.is_linear_question_navigation) {
                handleChangeQuestion(
                  payload?.navigation?.current_block_number !== null
                    ? `${payload?.navigation?.current_question_number}_${payload?.navigation?.current_block_number}`
                    : `${payload?.navigation?.current_question_number}`
                );
              } else {
                handleChangeQuestion(payload?.questions[0].question_id || null);
              }
            }
            break;
          }
          case "start_module": {
            let payload = data || null;
            let questionPapers = extractPapers(payload?.questions || []);
            payload.questions = normalizeQuestions(payload?.questions || []);
            setQuestionPapersData(questionPapers);
            let merged_questions = [];
            let qs = dataRef?.current?.questions?.slice();
            for (let i in qs) {
              merged_questions.push({
                ...qs[i],
                ...payload.questions.find(
                  (itmInner) => itmInner.question_id === qs[i].question_id
                ),
              });
            }
            setSimulationData({
              ...dataRef.current,
              navigation: {
                ...dataRef.current?.navigation,
                ...payload?.navigation,
              },
              questions: merged_questions,
              remaining_time: payload?.remaining_time,
            });
            if (
              payload?.navigation?.is_linear_module_navigation &&
              payload?.navigation?.is_module_active
            ) {
              handleChangeQuestion(
                payload?.navigation?.current_block_number !== null
                  ? `${payload?.navigation?.current_question_number}_${payload?.navigation?.current_block_number}`
                  : `${payload?.navigation?.current_question_number}`
              );
            } else if (!payload?.navigation?.is_linear_module_navigation) {
              if (payload?.navigation?.is_linear_question_navigation) {
                handleChangeQuestion(
                  payload?.navigation?.current_block_number !== null
                    ? `${payload?.navigation?.current_question_number}_${payload?.navigation?.current_block_number}`
                    : `${payload?.navigation?.current_question_number}`
                );
              } else {
                handleChangeQuestion(payload?.questions[0].question_id || null);
              }
            }
            break;
          }
          case "finish_module": {
            let payload = data || null;
            setSimulationData({
              ...dataRef.current,
              navigation: {
                ...dataRef.current?.navigation,
                ...payload?.navigation,
              },
              remaining_time: payload?.remaining_time,
            });
            break;
          }
          case "next_question": {
            let payloadResults = data ?? {};
            let questionPapers = extractPapers(
              [payloadResults?.question] || []
            );
            payloadResults.questions = normalizeQuestions(
              [payloadResults?.question] || []
            );
            console.log(paperDataRef.current);
            
            setQuestionPapersData([...paperDataRef.current || [], ...questionPapers]);
            let merged_questions = [];
            let qs = dataRef?.current?.questions?.slice();
            for (let i in qs) {
              merged_questions.push({
                ...qs[i],
                ...payloadResults.questions.find(
                  (itmInner) => itmInner.question_id === qs[i].question_id
                ),
              });
            }
            setSimulationData({
              ...dataRef.current,
              navigation: {
                ...dataRef.current?.navigation,
                ...payloadResults?.navigation,
              },
              questions: merged_questions,
            });
            handleChangeQuestion(
              payloadResults?.navigation?.current_block_number !== null
                ? `${payloadResults?.navigation?.current_question_number}_${payloadResults?.navigation?.current_block_number}`
                : `${payloadResults?.navigation?.current_question_number}`
            );
            break;
          }
          case "updated_answer": {
            let questionId = `${data?.question_number}`;
            if (data.block_number != undefined && data.block_number != null) {
              questionId = `${questionId}_${data.block_number}`;
            }
            handleUpdateQuestionData(questionId, data);
            break;
          }
          case "terminated": {
            clearInterval(intervalId);
            console.log(data);
            let payloadResults = data ?? {};
            if (payloadResults.questions) {
              payloadResults.questions = normalizeQuestions(
                payloadResults?.questions || []
              );
              setSimulationData({
                ...dataRef?.current,
                show_review: true,
                questions: payloadResults.questions,
                stats: payloadResults.stats,
                consumed_time: payloadResults.consumed_time,
              });
            } else
              setSimulationData({
                ...dataRef?.current,
                show_review: false,
                stats: payloadResults.stats || null,
                consumed_time: payloadResults.consumed_time,
              });
            handleEndSimulation(
              dataRef?.current?.has_closed_user ||
                payloadResults.consumed_time < calculateTotalTime()
            );
            break;
          }
          default:
            break;
        }
      };
      socketRef.current.onerror = (error) => {
        console.log("Socket onError", { error });
        if (is_login)
          throwSocketError(
            t(prefixedT("ERRORS.WRONG_PIN")),
            t(prefixedT("ERRORS.WRONG_PIN_TEXT")),
          );
        else
          throwSocketError(
            t(prefixedT("ERRORS.CONNECTION_ERROR_TITLE")),
            t(prefixedT("ERRORS.CONNECTION_ERROR")),
          );
      };
    } catch (error) {
      console.log("Socket onError in tryCatch", { error });
    }
  };

  const onPressAnswer = async (
    question_number,
    block_number,
    answer_number,
    is_uncertain = false
  ) => {
    const body = {
      event: "answer",
      data: {
        question_number: question_number,
        answer_number: answer_number,
        is_uncertain: is_uncertain,
        ...(block_number !== undefined && {
          block_number: block_number,
        }),
      },
    };
    console.log("Socket onPressAnswer", { body });
    sendToWebSocket(JSON.stringify(body));
  };

  const endTest = () => {
    const body = { event: "terminate", data: null };
    console.log("Socket endTest", { body });
    sendToWebSocket(JSON.stringify(body));
    clearInterval(intervalId);
  };

  const startModule = () => {
    const body = { event: "start_next_module", data: null };
    console.log("Socket next module", { body });
    sendToWebSocket(JSON.stringify(body));
  };

  const endModule = () => {
    const body = { event: "finish_module", data: null };
    console.log("Socket end module", { body });
    sendToWebSocket(JSON.stringify(body));
  };

  const nextQuestion = () => {
    const body = { event: "next_question", data: null };
    console.log("Socket next question", { body });
    sendToWebSocket(JSON.stringify(body));
  };

  const connectionStatus = socketRef.current?.readyState || -1;

  return {
    onPressAnswer,
    startModule,
    endModule,
    nextQuestion,
    closeSocket,
    endTest,
    createConnection,
    connectionStatus,
  };
};

export default WsHelper;
