import { useContext, useState, useRef, useEffect } from "react";
import "./Question.css";
import {
  Form,
  OverlayTrigger,
  Popover,
  FormCheck,
  Overlay,
  Spinner,
} from "react-bootstrap";
import uncertain_icon from "../../assets/icons/uncertain_icon.svg";
import { SimulationContext } from "../../context/SimulationContext";
import "katex/dist/katex.min.css";

import { useTranslation, Trans } from 'react-i18next';
import { prefixedT } from "../../i18n/utils";

import Latex from "react-latex-next";

import ImageZoom from "../ImageZoom/ImageZoom";
import error_review_img from "../../assets/icons/error-review.svg";
import error_uncertain_review_img from "../../assets/icons/uncertain_wrong.svg";
import uncertain_review_icon from "../../assets/icons/correct_uncertain.svg";

export default function StandardQuestion(questionProps) {
  const { simulationData } = useContext(SimulationContext);
  const [showTooltip, setShowTooltip] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const target = useRef();

  const { t, i18n } = useTranslation();

  const handleAnswerQuestion = (answer_number, is_uncertain = false) => {
    if (
      questionProps.data.selected_answer == answer_number &&
      is_uncertain === questionProps.data.is_uncertain
    )
      questionProps.handleAnswerQuestion(null, false);
    else questionProps.handleAnswerQuestion(answer_number, is_uncertain);
  };

  const resetAnswer = () => {
    questionProps.handleAnswerQuestion(null, false);
  };

  const formatText = (original_text = "") => {
    return original_text?.replaceAll("<p","< p")?.replaceAll("\n", "<br />")?.replaceAll("$$", "$");
  };

  useEffect(() => {
    setImageLoaded(false);
  }, [questionProps?.data?.image_url]);

  return (
    <div className={`col-9 exam_question_container px-4 pb-5`}>
      {questionProps.data && (
        <>
          <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center mb-2">
            {questionProps.data.incorrect_question && (
              <>
                {questionProps.data.was_incorrect &&
                  !questionProps.data.was_uncertain && (
                    <img
                      src={error_review_img}
                      alt="Sbagliata"
                      className="me-2 pointer"
                      ref={target}
                      onClick={() => setShowTooltip(!showTooltip)}
                    />
                  )}
                {questionProps.data.was_incorrect &&
                  questionProps.data.was_uncertain && (
                    <img
                      src={error_uncertain_review_img}
                      alt="Sbagliata ma non sicura"
                      className="me-2 pointer"
                      ref={target}
                      onClick={() => setShowTooltip(!showTooltip)}
                    />
                  )}
                {!questionProps.data.was_incorrect &&
                  questionProps.data.was_uncertain && (
                    <img
                      src={uncertain_review_icon}
                      alt="Non sicura ma corretta"
                      className="me-2 pointer"
                      ref={target}
                      onClick={() => setShowTooltip(!showTooltip)}
                    />
                  )}
                <Overlay
                  target={target.current}
                  show={showTooltip}
                  rootClose={true}
                  onHide={() => {
                    setShowTooltip(false);
                  }}
                  rootCloseEvent="click"
                  placement="bottom"
                  className={
                    simulationData?.is_bocconi_design
                      ? "bocconi"
                      : "center_modal"
                  }
                >
                  {({
                    placement,
                    arrowprops,
                    show: _show,
                    popper,
                    ...propsOverlay
                  }) => (
                    <div
                      {...propsOverlay}
                      style={{
                        position: "absolute",
                        backgroundColor: "#fff",
                        padding: "16px 20px",
                        color: "rgba(55, 55, 55, 0.75)",
                        fontSize: 16,
                        borderRadius: 16,
                        maxWidth: "250px",
                        height: "max-content",
                        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
                        ...propsOverlay.style,
                      }}
                      className={`tooltip_arrow_top ${
                        simulationData?.is_bocconi_design && "bocconi"
                      }`}
                    >
                      {questionProps.data.was_incorrect &&
                        !questionProps.data.was_uncertain && (
                          <>
                            {t(prefixedT("QUESTION.PAST_RESULT"))}
                            <img
                              src={error_review_img}
                              alt="incorrect"
                              className=""
                              style={{ maxWidth: "20px" }}
                            />{" "}
                            <strong className="text-tf">{t(prefixedT("QUESTION.ERROR"))}</strong>
                            {
                              t(prefixedT("QUESTION.PAST_RESULT_END"))
                            }
                          </>
                        )}
                      {questionProps.data.was_incorrect &&
                        questionProps.data.was_uncertain && (
                          <>
                            {t(prefixedT("QUESTION.PAST_ANSWER"))}
                            <img
                              src={error_review_img}
                              alt="incorrect"
                              className=""
                              style={{ maxWidth: "20px" }}
                            />{" "}
                            <strong className="text-tf">{t(prefixedT("QUESTION.WRONG_ANSWER"))}</strong>
                            {"e "}
                            <img
                              src={uncertain_icon}
                              alt="incorrect"
                              style={{ maxWidth: "20px" }}
                              className=""
                            />
                            <strong className="text-tf">
                              {t(prefixedT("QUESTION.UNSURE_ANSWER"))}
                            </strong>
                            {t(prefixedT("QUESTION.PAST_ANSWER_END"))}
                          </>
                        )}
                      {!questionProps.data.was_incorrect &&
                        questionProps.data.was_uncertain && (
                          <>
                            {t(prefixedT("QUESTION.PAST_ANSWER_CORRECT"))}
                            <img
                              src={uncertain_icon}
                              alt="incorrect"
                              style={{ maxWidth: "16px" }}
                              className=""
                            />
                            <strong className="text-tf">
                              {t(prefixedT("QUESTION.UNSURE_ANSWER"))}
                            </strong>
                            {t(prefixedT("QUESTION.PAST_ANSWER_END"))}
                          </>
                        )}
                    </div>
                  )}
                </Overlay>
              </>
            )}
            <p className=" mb-0">
              {`${t(prefixedT("QUESTION.QUESTION"))} ${questionProps.data.virtual_question_number} `}
              {questionProps.data.subject_name && (
                <span className="text-secondary">
                  {`- (${questionProps.data.subject_name})`}
                </span>
              )}
            </p>
          </div>
          {
            questionProps.data.is_sending_answer ?
              <Spinner animation="border" role="status"/>
            : ""
          }
          </div>
          {questionProps.data.paper &&
            questionProps.data.virtual_question_number !== undefined && (
              <div
                className="reader_preview"
                onClick={questionProps.handleOpenReader}
              >
                <p
                  style={{ whiteSpace: "pre-wrap" }}
                  dangerouslySetInnerHTML={{
                    __html: formatText(questionProps.data.paper),
                  }}
                />
              </div>
            )}
          <p className="mt-3 question-text" style={{ whiteSpace: "pre-wrap" }}>
            <Latex >{formatText(questionProps.data.question)}</Latex>
          </p>
          {questionProps.data.image_url && (
            <>
              <div className="image_preview">
                <ImageZoom>
                  <img
                    src={questionProps.data.image_url}
                    alt="Question Image"
                    style={{ visibility: imageLoaded ? "visible" : "hidden" }}
                    className="w-100"
                    onLoad={() => {
                      setImageLoaded(true);
                    }}
                  />
                </ImageZoom>
                <small className="d-block text-secondary">
                  {t(prefixedT("QUESTION.IMAGE_ZOOM"))}
                </small>
                {!imageLoaded && (
                  <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
              </div>
            </>
          )}

          <div className="d-flex mt-3 align-items-center justify-content-end">
            <span
              className={`reset_answer ${
                questionProps.data.selected_answer !== null &&
                questionProps.data.selected_answer !== undefined
                  ? ""
                  : "opacity-0"
              }`}
              style={
                questionProps.data.selected_answer !== null &&
                questionProps.data.selected_answer !== undefined
                  ? {}
                  : { cursor: "auto" }
              }
              onClick={resetAnswer}
            >
              {t(prefixedT("QUESTION.RESET_ANSWER"))}
            </span>
          </div>
          <div className="answers_list">
            {questionProps.data.answers.map((answer) => {
              return (
                <div
                  className={
                    "answer " +
                    (answer.answer_number === questionProps.data.selected_answer
                      ? "selected "
                      : "") +
                    (questionProps.data.is_uncertain ? "is_uncertain " : "")
                  }
                  key={answer.answer_number}
                >
                  <label
                    className="label_inner"
                    htmlFor={`answer-${questionProps.data.question_number}-${answer.answer_number}`}
                  >
                    <FormCheck className="mb-0">
                      <FormCheck.Input
                        type={"radio"}
                        className="hidden_radio mb-0"
                        name={`answer-${questionProps.data.question_number}-${answer.answer_number}`}
                        id={`answer-${questionProps.data.question_number}-${answer.answer_number}`}
                        value={answer.answer_number}
                        onClick={(event) => {
                          handleAnswerQuestion(event.target.value, false);
                        }}
                        checked={
                          answer.answer_number ===
                          questionProps.data.selected_answer
                        }
                        readOnly
                      />
                      <FormCheck.Label
                        className="mb-0"
                        htmlFor={`answer-${questionProps.data.question_number}-${answer.answer_number}`}
                      >
                        <span className="answer_label_number">
                          {String.fromCharCode(65 + answer.answer_number)}.
                        </span>
                        <div>
                          <Latex strict>{formatText(answer.text)}</Latex>
                          {answer?.image_url && <img src={answer.image_url} />}
                        </div>
                      </FormCheck.Label>
                    </FormCheck>
                  </label>
                  {simulationData.type === "incorrect_questions" &&
                    simulationData.tag !== "self_evaluation" && (
                      <div
                        className="uncertain_selector"
                        onClick={() => {
                          handleAnswerQuestion(answer.answer_number, true);
                        }}
                      >
                        <img src={uncertain_icon} />
                      </div>
                    )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
