import React from "react";
import logo_tf from "../../assets/icons/logo_menu_blue.svg";
import logo_bocconi from "../../assets/icons/bocconi/bocconi_logo.svg";
import logo_med from "../../assets/icons/themedtest.svg";
import logo_vet from "../../assets/icons/thevettest.svg";
import check from "../../assets/icons/check-certified.svg";
import "./TopBar.css";

import it_img from '../../assets/icons/it.svg'
import en_img from '../../assets/icons/en.svg'

import { useTranslation, Trans } from 'react-i18next';
import { prefixedT } from "../../i18n/utils";

export default function TopBar(props) {

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language
    localStorage.setItem('i18nextLng', lng); // Save the language to local storage
  };

  return (
    <header>
      <div
        className="container d-flex justify-content-between align-items-center"
        style={{ minHeight: "3.5em" }}
      >
        {props?.isBocconiDesign && (
          <div className="pw_label">
            <img
              src={logo_bocconi}
              alt="Università Bocconi"
              style={{ marginLeft: "-1em" }}
            />
          </div>
        )}
        {!props?.isBocconiDesign && (
          <div className="pw_label">
            <a target="_blank" href="https://thefacultyapp.com/">
              <img src={logo_tf} alt="thefaculty | SmartCreative SRL" />
            </a>
          </div>
        )}
        {/* {(props?.isMedDesign && props.simulationType === "comparative") && (
          <div className="pw_label">
              <img src={logo_med} style={{minWidth:'200px'}}/>
          </div>
        )} */}
        {/* {(props?.isVetDesign && props.simulationType === "comparative") && (
          <div className="pw_label">
              <img src={logo_vet} style={{minWidth:'200px'}}/>
          </div>
        )} */}
        {props.university_logo ? (
          <div className="d-flex align-items-center">
            {!props?.isBocconiDesign && (
              <div className="pw_label_smaller">
                <img
                  src={props?.university_logo?.image_url}
                  alt={props?.university_logo?.name}
                />
              </div>
            )}
            <div className="ms-2">
              {!props?.isBocconiDesign && (
                <span
                  className="d-block "
                  style={{
                    fontSize: "18px",
                    lineHeight: "20px",
                    fontWeight: "800",
                  }}
                >
                  {t(props?.university_logo?.name)}
                </span>
              )}
              {props?.university_logo?.sponsors && (
                <span
                  className="text-secondary align-middle"
                  style={{ fontSize: "14px", lineHeight: "20px" }}
                >
                  {!props?.isBocconiDesign && t(prefixedT("NAVBAR.SPONSORED_BY"))}
                  {props?.university_logo?.sponsors?.length === 1 ? (
                    props?.university_logo?.sponsors[0].type ===
                    "university" ? (
                      <>
                        {!props?.isBocconiDesign && (
                          <img
                            style={{ maxWidth: "65px" }}
                            src={
                              props?.university_logo?.sponsors[0]
                                ?.university_image_url
                            }
                            alt={
                              props?.university_logo?.sponsors[0]
                                ?.university_name
                            }
                          />
                        )}
                        <span>
                          {t(props?.university_logo?.sponsors[0]?.university_name)}
                        </span>
                      </>
                    ) : (
                      t(props?.university_logo?.sponsors[0]?.firstname) +
                      " " +
                      t(props?.university_logo?.sponsors[0]?.lastname)
                    )
                  ) : (
                    props?.university_logo?.sponsors?.length +
                    t(prefixedT("NAVBAR.ITALIAN_UNIVERSITY"))
                  )}{" "}
                  <img
                    style={{ marginTop: -5, verticalAlign: "middle" }}
                    src={check}
                  />
                </span>
              )}
            </div>
          </div>
        ): <div> 
          <div className="d-flex language_selector">
            <img src={it_img} className={i18n.language==="it" || i18n.language==="it-IT" ? "selected" : ''} onClick={()=>changeLanguage('it')}/>
            <img src={en_img} className={i18n.language==="en" || i18n.language==="en-EN" ? "selected" : ''} onClick={()=>changeLanguage('en')} />
          </div>  
        </div>}
      </div>
    </header>
  );
}
