import React, { useEffect } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import icon from "../../assets/icons/flag.svg";

import { useTranslation, Trans } from 'react-i18next';
import { prefixedT } from "../../i18n/utils";

import "./Modal.css";

export default function CloseResultModal(props) {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const onCloseModal = () => {
    props?.handleClose();
  };

  const confirmClose = () => {
    window.location.href = "/";
  };

  const style = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: 10010,
    },
    content: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      maxWidth: "350px",
      border: "0",
      background: "#fff",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      borderRadius: "24px",
      outline: "none",
      padding: "0",
    },
  };

  useEffect(() => {}, [props]);

  return (
    <>
      <Modal
        closeTimeoutMS={300}
        isOpen={props.show}
        contentLabel="modal"
        onRequestClose={onCloseModal}
        className={props?.isBocconiDesign ? "bocconi" : "center_modal"}
        overlayClassName="bottom_modal_overlay"
        ariaHideApp={false}
        style={style}
      >
        <div className="modal-content">
          <div className="d-flex justify-content-center">
            <img
              alt="Sei sicuro?"
              className="noselect header_image"
              src={icon}
            />
          </div>
          <h2
            id="modalTitle"
            className="modal__title"
            style={{
              textAlign: "center",
              marginBottom: "16px",
              marginTop: "16px",
            }}
          >
            {t(prefixedT("MODALS.BACK_HOME"))}
          </h2>
          <div style={{ textAlign: "center", marginBottom: "24px" }}>
            <p className="text-secondary">
              {props?.isBocconiDesign
                ? t(prefixedT("MODALS.BACK_HOME_TEXT"))
                : t(prefixedT("MODALS.BACK_HOME_TEXT_BOCCONI"))}
            </p>
          </div>
          <div className="">
            <button
              id="closeModalButton"
              onClick={confirmClose}
              className="w-100"
            >
              {t(prefixedT("BUTTONS.EXIT_AND_HOME"))}
            </button>
            <button
              id="closeModalButton"
              onClick={onCloseModal}
              className="mt-2 white w-100"
            >
              {t(prefixedT("BUTTONS.DISCARD"))}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
