import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SimulationContext,
  QuestionPapersContext,
} from "../../context/SimulationContext";
import Controller from "../../_components/Controller/Controller";
import Header from "../../_components/TopBar/TopBar";
import ErrorModal from "../../_components/Modals/ErrorModal";
import Navigation from "../../_components/Navigation/Navigation";
import WsHelper from "../../helpers/ws_helper";

import "./SimulationScreen.css";
import WaitingScreen from "./WaitingScreen";
import EndExamModal from "../../_components/Modals/EndExamModal";
import ExpiredTimeModal from "../../_components/Modals/ExpiredTimeModal";
import AvatarOverlay from "./AvatarOverlay";
import StandardQuestion from "../../_components/Question/StandardQuestion";
import PaperReader from "../../_components/Question/PaperReader";
import ModuleWaitingScreen from "./ModuleWaitingScreen";

export default function SimulationScreen() {
  let navigate = useNavigate();
  let location = useLocation();

  const [errorModalDetails, setErrorModalDetails] = useState({
    show: false,
    text: "",
    title: "",
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const { simulationData, setSimulationData } = useContext(SimulationContext);
  const { questionPapersData, setQuestionPapersData } = useContext(
    QuestionPapersContext
  );
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const [endExamModalShow, setEndExamModalShow] = useState({
    show: false,
    onlyEndModule: false,
    showGenericMessage: false,
  });
  const [showExipredTimeModal, setShowExipredTimeModal] = useState(false);
  const [globalEndDate, setGlobalEndDate] = useState(0);
  const [openReader, setOpenReader] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(true);

  // Navigation Handler
  const handleChangeQuestion = (question_id) => {
    setCurrentQuestion(question_id);
    if (simulationData?.navigation?.is_linear_question_navigation)
      nextQuestion();
  };

  const GetFromLocalStorage = function (key) {
    return JSON.parse(localStorage.getItem(key));
  };

  const userHasSelectedAnswer = useMemo(() => {
    let selectedAnswer = simulationData?.questions?.find(
      (q) => q.question_id === currentQuestion
    )?.selected_answer;
    return selectedAnswer !== null && selectedAnswer !== undefined;
  }, [simulationData]);

  // Simulation Answer Handler
  const handleAnswerQuestion = (answer_number, is_uncertain = false) => {
    answer_number = answer_number !== null ? parseInt(answer_number) : null;
    let qs = simulationData.questions.slice();
    for (let i in qs) {
      if (qs[i].question_id === currentQuestion) {
        qs[i]["is_sending_answer"] = true
        setSimulationData({
          ...simulationData,
          questions: qs,
        });

        onPressAnswer(
          qs[i].question_number,
          qs[i].block_number,
          answer_number,
          is_uncertain
        );
        break;
      }
    }
  };

  const handleCloseErrorModal = () => {
    setErrorModalDetails({
      ...errorModalDetails,
      show: false,
    });
  };

  const throwSocketError = (title, text) => {
    setErrorModalDetails({
      ...errorModalDetails,
      show: true,
      title: title,
      text: text,
    });
  };

  const handleAskEndSimulation = (override = false) => {
    if (override) {
      setEndExamModalShow({
        ...endExamModalShow,
        show: true,
        onlyEndModule: false,
      });
    } else {
      if (
        simulationData.navigation?.is_linear_module_navigation &&
        simulationData.navigation?.current_module_number + 1 !==
          simulationData.navigation?.modules.length
      )
        setEndExamModalShow({
          ...endExamModalShow,
          show: true,
          onlyEndModule: true,
        });
      else
        setEndExamModalShow({
          ...endExamModalShow,
          show: true,
          onlyEndModule: false,
        });
    }
  };

  const handleCloseEndExamModal = () => {
    setEndExamModalShow({
      ...endExamModalShow,
      show: false,
      onlyEndModule: false,
    });
  };

  const handleConfirmCloseTest = () => {
    handleCloseEndExamModal();
    setSimulationData({
      ...simulationData,
      has_closed_user: true,
    });
    endTest();
  };

  const handleEndModule = () => {
    setEndExamModalShow({
      ...endExamModalShow,
      show: false,
      onlyEndModule: false,
    });
    endModule();
  };

  const calculateTotalTime = () => {
    let total = 0;
    simulationData.questions.forEach((q) => {
      total += parseInt(q.timeout);
    });
    return total;
  };

  const handleEndSimulation = (has_closed_user) => {
    if (has_closed_user) navigate("/results", { replace: true });
    else setShowExipredTimeModal(true);
  };

  const handleSetSimulationData = (data) => {
    setSimulationData(data);
    setIsLoaded(true);
  };

  const handleOpenReader = () => {
    setOpenReader(true);
  };

  const handleCloseReader = () => {
    setOpenReader(false);
  };

  const saveGlobalEndDate = (time) => {
    setGlobalEndDate(time);
  };

  const simulationSocket = useRef(null);
  const {
    onPressAnswer,
    closeSocket,
    startModule,
    endModule,
    nextQuestion,
    endTest,
    createConnection,
    connectionStatus,
  } = WsHelper(
    simulationSocket,
    handleSetSimulationData,
    handleEndSimulation,
    throwSocketError,
    handleChangeQuestion
  );

  useEffect(() => {
    setIsConnecting(true);
    if (!isConnecting && location.state?.userPin && connectionStatus === -1)
      createConnection(location.state.userPin);
    else navigate("/", { replace: true });
  }, [location?.state?.userPin]);

  return (
    <div
      className={`noselect simulation_screen ${
        simulationData?.is_bocconi_design && "bocconi_font"
      }`}
    >
      <Header
        university_logo={simulationData?.admission_test}
        isBocconiDesign={simulationData?.is_bocconi_design}
        isMedDesign={simulationData?.is_med_design}
        isVetDesign={simulationData?.is_vet_design}
        simulationType={simulationData?.type}
      />
      {isLoaded && simulationData ? (
        <>
          {(simulationData?.navigation?.is_linear_module_navigation &&
            simulationData?.navigation?.is_module_active) ||
          !simulationData?.navigation?.is_linear_module_navigation ? (
            <>
              {openReader && !showExipredTimeModal && (
                <PaperReader
                  expiryTime={globalEndDate}
                  data={
                    simulationData.questions.find(
                      (q) => q.question_id === currentQuestion
                    ) || null
                  }
                  paperData={
                    questionPapersData.find(
                      (p) =>
                        p?.question_number ===
                        simulationData.questions.find(
                          (q) => q?.question_id === currentQuestion
                        )?.question_number
                    ) || null
                  }
                  handleCloseReader={handleCloseReader}
                  isBocconiDesign={simulationData?.is_bocconi_design}
                />
              )}
              <section id="examContent">
                <div className="row">
                  <Navigation
                    questions={simulationData.questions}
                    navigationData={simulationData.navigation}
                    simulationType={simulationData?.type}
                    handleChangeQuestion={handleChangeQuestion}
                    currentQuestion={currentQuestion}
                    handleCloseSimulation={handleAskEndSimulation}
                    remaining_time={simulationData.remaining_time}
                    saveEndDate={saveGlobalEndDate}
                    showConfirmPopup={showConfirmPopup}
                    setShowConfirmPopup={setShowConfirmPopup}
                    isBocconiDesign={simulationData.is_bocconi_design}
                  />
                  {simulationData.questions.find(
                    (q) => q.question_id === currentQuestion
                  )?.answers && (
                    <StandardQuestion
                      data={simulationData.questions.find(
                        (q) => q.question_id === currentQuestion
                      )}
                      handleAnswerQuestion={handleAnswerQuestion}
                      currentQuestion={currentQuestion}
                      handleOpenReader={handleOpenReader}
                    />
                  )}
                </div>
              </section>
              <Controller
                currentQuestion={currentQuestion}
                handleChangeQuestion={handleChangeQuestion}
                university_logo={
                  simulationData && simulationData.admission_test
                }
                questions={simulationData.questions}
                navigationData={simulationData.navigation}
                isBocconiDesign={simulationData.is_bocconi_design}
                userHasSelectedAnswer={userHasSelectedAnswer}
                showConfirmPopup={showConfirmPopup}
              />
              <ExpiredTimeModal show={showExipredTimeModal} />
            </>
          ) : (
            <>
              <ModuleWaitingScreen
                handleCloseSimulation={handleAskEndSimulation}
                startModule={startModule}
              />
            </>
          )}
          <EndExamModal
            show={endExamModalShow.show}
            navigationData={simulationData.navigation}
            simulationType={simulationData?.type}
            endModuleOnly={endExamModalShow.onlyEndModule}
            endModule={handleEndModule}
            questions={simulationData.questions || []}
            handleConfirm={handleConfirmCloseTest}
            handleClose={handleCloseEndExamModal}
            isBocconiDesign={simulationData?.is_bocconi_design}
          />
        </>
      ) : (
        <WaitingScreen />
      )}
      {!GetFromLocalStorage("avatar_show") ? <AvatarOverlay /> : ""}
      <ErrorModal
        show={errorModalDetails.show}
        title={errorModalDetails.title}
        text={errorModalDetails.text}
        handleClose={handleCloseErrorModal}
      />
    </div>
  );
}
