import React, { useEffect } from "react";
import Modal from "react-modal"
import icon from "../../assets/icons/timer-red.svg";
import { useNavigate } from "react-router-dom";
import './Modal.css'

import { useTranslation, Trans } from 'react-i18next';
import { prefixedT } from "../../i18n/utils";

export default function ExpiredTimeModal(props) {

    let navigate = useNavigate()

    const { t, i18n } = useTranslation();
    
    const onCloseModal = () => {
        props?.handleClose();
    }

    const confirmClose = () => {
        navigate("/results", {replace: true})
    }

    const style = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 10010
        },
        content: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: "translate(-50%, -50%)",
            width: '100%',
            maxWidth: "350px",
            border: '0',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: "24px",
            outline: 'none',
            padding: '0'
        }
    }

    useEffect(() => {

    }, [props])

    return (
        <>
            <Modal
                closeTimeoutMS={300}
                isOpen={props.show}
                contentLabel="modal"
                onRequestClose={onCloseModal}
                className="center_modal"
                overlayClassName="bottom_modal_overlay"
                ariaHideApp={false}
                style={style}
            >
                <div className="modal-content">
                    <div className="d-flex justify-content-center">
                        <img
                            alt="Tempo esaurito"
                            className="noselect header_image"
                            src={icon}
                        />
                    </div>
                    <h2 id="modalTitle" className="modal__title" style={{ textAlign: 'center', marginBottom: "16px", marginTop: "16px" }}>{t(prefixedT("MODALS.EXPIRED_TIME_TITLE"))}</h2>
                    <div style={{ textAlign: 'center', marginBottom: "24px" }}>
                        <p className="text-secondary">{t(prefixedT("MODALS.EXPIRED_TIME_TEXT"))}</p>
                    </div>
                    <div className="">
                        <button id="closeModalButton" onClick={confirmClose} className="w-100">
                            {t(prefixedT("BUTTONS.VIEW_RESULTS"))}
                        </button>
                    </div>
                    
                </div>
            </Modal>
        </>
    );
}