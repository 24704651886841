import React, { useEffect, useState, useContext, useRef } from "react";

import icon_arrow from "../../../assets/icons/arrow-back.svg";
import icon_arrow_bocconi from "../../../assets/icons/bocconi/arrow-back.svg";
import icon_correct_answer from "../../../assets/icons/answer_correct.svg";
import icon_wrong_answer from "../../../assets/icons/answer_wrong.svg";
import icon_missing_answer from "../../../assets/icons/answer_null.svg";

import arrow_left from "../../../assets/icons/arrow_previous.svg";
import arrow_right from "../../../assets/icons/arrow_next.svg";

import "../../../_components/Navigation/Navigation.css";
import "./NavigationReview.css";
import { useNavigate } from "react-router-dom";

import { useTranslation, Trans } from 'react-i18next';
import { prefixedT } from "../../../i18n/utils";

export default function NavigationReview(props) {
  let navigate = useNavigate();
  const changeQuestion = (index) => {
    props.handleChangeQuestion(index);
  };

  const { t, i18n } = useTranslation();

  const [selectedModule, setSelectedModule] = useState(0);

  useEffect(() => {
    if (
      props.questions.find((q) => q.question_id === props.currentQuestion)
        ?.module_number != selectedModule
    )
      setSelectedModule(
        props.questions.find((q) => q.question_id === props.currentQuestion)
          ?.module_number
      );
  }, [props.currentQuestion]);

  return (
    <div className="col-3 navigation_bar pb-5">
      <div className="d-flex align-items-top justify-content-start">
        <img
          src={props?.isBocconiDesign ? icon_arrow_bocconi : icon_arrow}
          className="pointer"
          onClick={() => {
            navigate("/results", { replace: true });
          }}
        />
      </div>
      {props.navigationData.is_linear_module_navigation ? (
        <div className="mt-5 mb-0">
          <p className="text-black mb-0" style={{ fontWeight: "800" }}>
            {t(prefixedT("NAVIGATION.MODULE"))} {selectedModule + 1}:{" "}
            {props.navigationData.modules[selectedModule]?.name}{" "}
          </p>
        </div>
      ) : (
        ""
      )}
      <div className="mt-4 questions_nav">
        {props.navigationData.is_linear_module_navigation ? (
          <div className="mt-3">
            {props.navigationData.current_module_number ? (
              <div className="d-flex w-100 justify-content-between">
                <div
                  className="d-flex pointer"
                  onClick={() => {
                    setSelectedModule(selectedModule - 1);
                  }}
                  style={{
                    visibility: selectedModule != 0 ? "visible" : "hidden",
                  }}
                >
                  <img className="me-1" src={arrow_left} />
                  <span>{t(prefixedT("NAVIGATION.MODULE"))} {selectedModule}</span>
                </div>
                <div
                  className="d-flex pointer"
                  onClick={() => {
                    setSelectedModule(selectedModule + 1);
                  }}
                  style={{
                    visibility:
                      selectedModule + 1 < props.navigationData.modules.length
                        ? "visible"
                        : "hidden",
                  }}
                >
                  <span>{t(prefixedT("NAVIGATION.MODULE"))} {selectedModule + 2}</span>
                  <img className="ms-1" src={arrow_right} />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="mt-3 questions_nav">
              {props.questions.map((question, index) => {
                let has_answer =
                  question.selected_answer !== null &&
                  !isNaN(question.selected_answer)
                    ? true
                    : false;
                if (question.module_number === selectedModule)
                  return (
                    <div
                      className={
                        "question_el_external " +
                        (question.isBlockQuestion ? "block_wrapped " : "") +
                        (question.isFirstBlock ? "block_wrapped_first " : "") +
                        (question.isLastBlock ? "block_wrapped_last" : "")
                      }
                    >
                      <div
                        key={index}
                        onClick={() => {
                          changeQuestion(question.question_id);
                        }}
                        className={
                          "question_el review_q_nav " +
                          (has_answer ? "answered " : "") +
                          (question.is_uncertain ? "is_uncertain " : "") +
                          (question.question_id === props.currentQuestion
                            ? "current"
                            : "")
                        }
                      >
                        {parseInt(index) + 1}
                        {!has_answer ? (
                          <img
                            src={icon_missing_answer}
                            className="answer_navigation_result"
                          />
                        ) : question.answers.find(
                            (a) =>
                              a.answer_number === question.selected_answer &&
                              a.is_correct_answer
                          ) ? (
                          <img
                            src={icon_correct_answer}
                            className="answer_navigation_result"
                          />
                        ) : (
                          <img
                            src={icon_wrong_answer}
                            className="answer_navigation_result"
                          />
                        )}
                      </div>
                    </div>
                  );
                else return "";
              })}
            </div>
          </div>
        ) : (
          <div className="mt-4 questions_nav">
            {props.questions.map((question, index) => {
              let has_answer =
                question.selected_answer !== null &&
                !isNaN(question.selected_answer)
                  ? true
                  : false;

              return (
                <div
                  className={
                    "question_el_external " +
                    (question.isBlockQuestion ? "block_wrapped " : "") +
                    (question.isFirstBlock ? "block_wrapped_first " : "") +
                    (question.isLastBlock ? "block_wrapped_last" : "")
                  }
                >
                  <div
                    key={index}
                    onClick={() => {
                      changeQuestion(question.question_id);
                    }}
                    className={
                      "question_el review_q_nav " +
                      (has_answer ? "answered " : "") +
                      (question.is_uncertain ? "is_uncertain " : "") +
                      (question.question_id === props.currentQuestion
                        ? "current"
                        : "")
                    }
                  >
                    {parseInt(index) + 1}
                    {!has_answer ? (
                      <img
                        src={icon_missing_answer}
                        className="answer_navigation_result"
                      />
                    ) : question.answers.find(
                        (a) =>
                          a.answer_number === question.selected_answer &&
                          a.is_correct_answer
                      ) ? (
                      <img
                        src={icon_correct_answer}
                        className="answer_navigation_result"
                      />
                    ) : (
                      <img
                        src={icon_wrong_answer}
                        className="answer_navigation_result"
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
