import { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import {
  SimulationContext,
  QuestionPapersContext,
} from "./context/SimulationContext";
import { GlobalDebug } from "./console-remove";
import * as gtag from "./utils/gtag";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import ErrorModal from "./_components/Modals/ErrorModal";
import InitialScreen from "./screens/InitialScreen/InitialScreen";
import SimulationScreen from "./screens/SimulationScreen/SimulationScreen";
import ResultScreen from "./screens/ResultsScreen/ResultScreen";
import QuestionRevisionScreen from "./screens/QuestionsReviewScreen/QuestionReviewScreen";

function App() {
  const [errorModalDetails, setErrorModalDetails] = useState({
    show: false,
    text: "",
    title: "",
  });
  const [simulationData, setSimulationData] = useState(null);
  const value = { simulationData, setSimulationData };
  const [questionPapersData, setQuestionPapersData] = useState(null);
  const paperValue = { questionPapersData, setQuestionPapersData };

  const handleCloseErrorModal = () => {
    setErrorModalDetails({
      ...errorModalDetails,
      show: false,
    });
  };

  useEffect(() => {
    if (process.env.REACT_APP_DISABLE_CONSOLE)
      GlobalDebug(process.env.REACT_APP_ENVIRONMENT === "development");
  }, []);

  const location = useLocation();

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    handleRouteChange(location.pathname);
  }, [location]);

  return (
    <div className={`App ${simulationData?.is_bocconi_design && "bocconi"}`}>
      <SimulationContext.Provider value={value}>
        <QuestionPapersContext.Provider value={paperValue}>
          <div id="mainContent" className="container ">
            <Routes>
              <Route index path="/" element={<InitialScreen />} />
              <Route path="/simulation" element={<SimulationScreen />} />
              <Route path="/results" element={<ResultScreen />} />
              <Route path="/revision" element={<QuestionRevisionScreen />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </QuestionPapersContext.Provider>
      </SimulationContext.Provider>
      <ErrorModal
        show={errorModalDetails.show}
        title={errorModalDetails.title}
        text={errorModalDetails.text}
        handleClose={handleCloseErrorModal}
      />
    </div>
  );
}

export default App;
