import { useState, createContext } from "react"
const SimulationData = {
    simulationData: null,
    setSimulationData: () => {}
};
const QuestionsPapersData = {
    questionPapersData: [],
    setQuestionPapersData: () => {}
};

export const SimulationContext = createContext(SimulationData);
export const QuestionPapersContext = createContext(QuestionsPapersData);