import "./PaginationControl.css";
import qm_img from "../../assets/icons/question-mark.svg";
import qm_bocconi_img from "../../assets/icons/bocconi/question-mark.svg";
import { Overlay, OverlayTrigger } from "react-bootstrap";
import React, { useRef, useState } from "react";

import check from "../../assets/icons/check-certified.svg";
import SkipQuestionModal from "../Modals/SkipQuestionModal";

import { useTranslation, Trans } from 'react-i18next';
import { prefixedT } from "../../i18n/utils";

export default function Controller(props) {

  const { t, i18n } = useTranslation();
  const [showSkipQuestionModal, setShowSkipQuestionModal] = useState({
    show: false,
    onPress: () => {},
  });

  const changeQuestion = (index, skipConfirmPopup = false) => {
    if (
      (props?.showConfirmPopup ||
        (!props?.showConfirmPopup && !props?.userHasSelectedAnswer)) &&
      props.navigationData?.is_linear_question_navigation &&
      !skipConfirmPopup
    ) {
      setShowSkipQuestionModal({
        show: true,
        onPress: () => {
          changeQuestion(index, true);
        },
      });
      return;
    }
    props.handleChangeQuestion(index);
  };

  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef();

  return (
    <div className="exam_pagination_control">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-3 justify-content-around d-flex">
            <div
              className="d-flex align-items-center justify-content-center pointer"
              ref={target}
              onClick={() => setShowTooltip(!showTooltip)}
            >
              <img
                src={props?.isBocconiDesign ? qm_bocconi_img : qm_img}
                alt=""
              />
              <span
                className="ms-1"
                style={{ fontSize: "20px", color: "var(--bs-primary)" }}
              >
                {t(prefixedT("NAVIGATION.HELP"))}
              </span>
            </div>
            <Overlay
              target={target.current}
              show={showTooltip}
              rootClose={true}
              onHide={() => {
                setShowTooltip(false);
              }}
              rootCloseEvent="click"
              placement="top"
            >
              {({
                placement,
                arrowProps,
                show: _show,
                popper,
                ...propsOverlay
              }) => (
                <div
                  {...propsOverlay}
                  style={{
                    position: "absolute",
                    backgroundColor: "#fff",
                    padding: "16px 20px",
                    color: "rgba(55, 55, 55, 0.75)",
                    fontSize: 16,
                    borderRadius: 16,
                    maxWidth: "265px",
                    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
                    ...propsOverlay.style,
                  }}
                  className={`tooltip_arrow ${
                    props?.isBocconiDesign && "bocconi"
                  }`}
                >
                  {t(prefixedT("NAVIGATION.HELP_TEXT_START"))}
                  <strong className="text-tf">assistenza@thefaculty.com</strong>
                  {t(prefixedT("NAVIGATION.HELP_TEXT_END"))}
                </div>
              )}
            </Overlay>
          </div>
          <div className="col-9 d-flex align-items-center justify-content-between">
            <button
              className="white"
              disabled={
                props.currentQuestion === props.questions[0]?.question_id
              }
              data-invisible={
                props.currentQuestion === props.questions[0]?.question_id ||
                props.navigationData?.is_linear_question_navigation
              }
              onClick={() => {
                changeQuestion(
                  props.questions[
                    parseInt(
                      props.questions.findIndex(
                        (q) => q.question_id === props.currentQuestion
                      ) - 1
                    )
                  ]?.question_id
                );
              }}
            >
              {t(prefixedT("NAVIGATION.PREVIOUS"))}
            </button>
            {!props?.isBocconiDesign && (
              <span
                className="text-secondary align-middle"
                style={{ fontSize: "14px" }}
              >
                &copy; {new Date().toLocaleString("it-It", { year: "numeric" })}{" "}
                - {t(props?.university_logo?.name)}{" "}
                {props?.university_logo?.sponsors && (
                  <img style={{ verticalAlign: "text-bottom" }} src={check} />
                )}
              </span>
            )}
            <button
              className="white"
              disabled={
                props.questions &&
                (parseInt(
                  props.questions.findIndex(
                    (q) => q.question_id === props.currentQuestion
                  )
                ) +
                  1 ===
                  props.questions.length ||
                  (props?.navigationData?.is_linear_module_navigation &&
                    props.questions[
                      parseInt(
                        props.questions.findIndex(
                          (q) => q.question_id === props.currentQuestion
                        )
                      ) + 1
                    ]?.module_number !==
                      props.navigationData?.current_module_number &&
                    props.questions[
                      parseInt(
                        props.questions.findIndex(
                          (q) => q.question_id === props.currentQuestion
                        )
                      ) + 1
                    ]?.blocked_question))
              }
              data-invisible={
                props.questions &&
                (parseInt(
                  props.questions.findIndex(
                    (q) => q.question_id === props.currentQuestion
                  )
                ) +
                  1 ===
                  props.questions.length ||
                  (props?.navigationData?.is_linear_module_navigation &&
                    props.questions[
                      parseInt(
                        props.questions.findIndex(
                          (q) => q.question_id === props.currentQuestion
                        )
                      ) + 1
                    ]?.module_number !==
                      props.navigationData?.current_module_number &&
                    props.questions[
                      parseInt(
                        props.questions.findIndex(
                          (q) => q.question_id === props.currentQuestion
                        )
                      ) + 1
                    ]?.blocked_question))
              }
              onClick={() => {
                changeQuestion(
                  props.questions[
                    parseInt(
                      props.questions.findIndex(
                        (q) => q.question_id === props.currentQuestion
                      ) + 1
                    )
                  ]?.question_id
                );
              }}
            >
              {props?.userHasSelectedAnswer || props?.isReviewScreen || !props?.navigationData?.is_linear_question_navigation
                ? t(prefixedT("NAVIGATION.NEXT"))
                : t(prefixedT("NAVIGATION.SKIP"))}
            </button>
          </div>
        </div>
      </div>
      <SkipQuestionModal
        show={showSkipQuestionModal?.show}
        handleConfirm={showSkipQuestionModal?.onPress}
        handleClose={() => {
          setShowSkipQuestionModal(false);
        }}
        isBocconiDesign={props?.isBocconiDesign}
        isSkip={!props?.userHasSelectedAnswer}
      />
    </div>
  );
}
