import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './i18n/i18n';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://0e465de4a2844146a7fe4e70f534efd0@o1240163.ingest.sentry.io/4504836442947584",
  integrations: [new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
  }), new Sentry.Replay()],
  release: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENVIRONMENT,
  tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_SESSION_RATE),
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.5,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
