import Lottie from 'lottie-react';
import hourglassAnimation from '../../assets/icons/hourglass.json'
import logo_test from "../../assets/icons/test_section.svg"
import { useNavigate } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';
import { prefixedT } from "../../i18n/utils";

export default function WaitingScreen () {

    let navigate = useNavigate()
    const { t, i18n } = useTranslation();

    return(
        <div className='row justify-content-center waiting-page'>
            <div className='col-6'>
                <Lottie
                    animationData={hourglassAnimation}
                    autoplay={true}
                    loop={1000}
                    id="time_waiting_animation"
                />
                <h2 className='text-center'>{t(prefixedT("SIMULATION_WAITING_SCREEN.TITLE"))}</h2>
                <p className='text-center'>
                    <Trans
                        i18nKey={prefixedT("SIMULATION_WAITING_SCREEN.TEXT")}
                        components={{
                            strong: <strong />,
                            image: <img src={logo_test} className="inline-icon-small" />,
                        }}
                    />
                </p>
                <div className='d-flex justify-content-center'>
                    <button type='button' onClick={()=>{navigate("/",{replace:true})}} className='mt-5'>
                        {t(prefixedT("BUTTONS.GO_BACK"))}
                    </button>
                </div>
            </div>
        </div>
        
    )
}